.credits {
    position: relative;
    padding: 120px 0; min-height: 320px;
    overflow: hidden;
}

.credits__container {
    display: flex; flex-wrap: wrap;
}
.credits__item {
    display: flex; flex-direction: column;
    width: 33.33%; padding-bottom: 24px; padding-right: 24px;

    @include tablet {width: 25%;}
    @include tablet-sm {width: 50%;}
    @include mobile {width: 100%;}
}

.credits__item-title {
    display: block; max-height: 170px; margin-bottom: 5px;
    font-size: 18px; line-height: 24px; font-weight: 500; color: $bordeaux;
    overflow: auto;
    @include scrollbar

}
.credits__item-text {
    max-height: 190px;
    line-height: 24px;
    overflow: auto;
    @include scrollbar
}

.credits__underlay {
    position: absolute; left: 0; bottom: 35px; z-index: -1;
    display: flex; align-items: flex-end;
    width: 100%; height: calc(100% - 35px);
    background-image: linear-gradient(180deg, rgba(255,255,255,0) 0%, #1a1713 100%);
    opacity: 0.05; overflow: hidden;
}
.credits__underlay-text {
    height: 21.4vw;
    font-size: 30vw; color: $white; font-family: 'Elgoc';
}