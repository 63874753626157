/* STUDY CARD */
.studyCard {
    display: block; width: 100%; max-width: 500px; height: 100%;
    color: white;
    background: $grey;
    &--dark-blue {background-color: $study-dark-blue}
    &--light-blue {background-color: $study-light-blue}
    &--bordeaux {background-color: $study-bordeaux}
    &--red {background-color: $study-red}
    &--pink {background-color: $study-pink}
    &--orange {background-color: $study-orange}
    &--dark-grey {background-color: $study-dark-grey}
    &--light-grey {background-color: $study-light-grey}
    &--dark-brown {background-color: $study-dark-brown}
    &--light-brown {background-color: $study-light-brown}
    &--dark-green {background-color: $study-dark-green}
    &--light-green {background-color: $study-light-green}
    & > * {
        pointer-events: none;
    }
}
.studyCard__content{
    display: flex; flex-direction: column; align-items: center;
    height: 100%;
}
.studyCard__heading {
    display: flex; align-items: center; justify-content: center;
    width: 100%; height: 150px; padding: 30px 18% 0; margin-bottom: 15px;
    overflow: hidden;
}
.studyCard__title {
    font-family: 'Elgoc'; font-size: 28px; text-align: center; 
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    max-height: 110px;
    margin-bottom: -10px;
}
.studyCard__body {
    flex-grow: 1;
    width: 100%; padding: 0 18%;
    min-height: 1px;
}
.studyCard__cover {
    position: relative;
    width: 100%;
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.84);
    box-shadow: 0 22px 44px rgba(26, 23, 19, 0.4);
    overflow: hidden; backface-visibility: hidden; -webkit-mask-image: -webkit-radial-gradient(white, black);
    max-height: 100%;
}
.studyCard__book {
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.84);
    backface-visibility: hidden;
}
.studyCard__cover-image {
    display: block; width: 100%;
    backface-visibility: hidden;
}
.studyCard__cover-edge {
    position: absolute; left: 0; top: 0; z-index: 2;
    width: 12px; height: 100%;
    background-color: rgba(0, 0, 0, 0.2); 
    backface-visibility: hidden; transform: translate3d(0,0,0);
    &::after {
        content: ""; position: absolute; right: 0; top: 0;
        height: 100%;
        border-left: 1px solid rgba(255,255,255,0.2);
        border-right: 1px solid rgba(0,0,0,0.5);
        transform: translate3d(0,0,0) scaleX(1.2); backface-visibility: hidden;
    }
}
.studyCard__footer {
    // display: flex; justify-content: space-between;
    width: 100%; /*height: 100px;*/ padding: 20px 18%;
    text-align: center;
}
.studyCard__infos {
    // display: inline-flex;
    display: none;
    padding-right: 15px;
    span {
        height: 20px; margin-right: 12px; display: inline-flex;
        &:last-child {margin: 0;}
    }
    .icon {
        width: 16px; height: 16px; margin-top: -1px; margin-right: 4px;
        fill: $white;
    }
}
.studyCard__date {}
.studyCard__authors{}
.studyCard__author{display: inline-block;}
.studyCover__container {
    position: relative;
    & > img {display: block;}
}
.studyCover__edge {
    position: absolute; left: 0; top: 0; z-index: 2;
    width: 6%; height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    backface-visibility: hidden; transform: translate3d(0,0,0);
    &::after {
        content: ""; position: absolute; right: 0; top: 0;
        height: 100%;
        border-left: 1px solid rgba(255,255,255,0.2);
        border-right: 1px solid rgba(0,0,0,0.5);
        transform: translate3d(0,0,0) scaleX(1.2); backface-visibility: hidden;
    }
}