.twitterFeed{}
.twitterFeed__title{
	display: flex; align-items: center; 
	margin-bottom: 15px; 
	font-size: 16px; font-weight: 500; letter-spacing: 0.89px; text-transform: uppercase; color: $social-twitter;
}
.twitterFeed__footer{position: relative; margin-top: -5px; border-top: 5px solid #fff;}
.twitterFeed__icon{
	margin-bottom: 14px; margin-right: 10px;
	.icon{
		display: block; width: 24px; height: 20px; 
		fill: $social-twitter; color: red;
	}
	&:hover .icon {fill: $social-facebook;}
}
.twitterFeed__item{
	font-size: 18px; font-weight: 400; line-height: 24px;
	&+&{margin-top: 30px;}
	strong{font-weight: 600;}
}
.twitterFeed__text {
	padding-top: 3px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;  
	overflow: hidden;
}
.twitterFeed__img-link{display: block;}
.twitterFeed__img{
	max-width: 100%; max-height: 300px; margin-top: 18px;
}