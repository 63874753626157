/* CONTENT QUOTE */
.contentQuote {
    padding: 35px 0;
}
.contentQuote__container {
    display: flex; justify-content: center; align-items: center; flex-direction: column;
    padding: 20px 0 90px;
}
.contentQuote__heading {
    position: relative;
    display: flex; justify-content: center; align-items: center;
    padding: 65px 0 80px;
    &::before {
        content: "“"; position: absolute; left: 50%; top: 0;
        font-family: 'Elgoc'; font-size: 380px; color: $bordeaux;
        opacity: 0.1; transform: translateX(-50%);
        pointer-events: none;
    }
    @include tablet {padding: 65px 0 40px;}
    @include mobile {padding: 60px 0 30px;}
}
.contentQuote__title {
    color: $bordeaux; font-size: 16px; text-transform: uppercase; font-weight: 500;
    
}
.contentQuote__body {
    position: relative; z-index: 1;
    display: block; max-width: 100%;
    font-family: 'Elgoc'; font-size: 46px; text-align: center;
    &::before {
        content: "”"; position: absolute; left: 50%; top: 100%;
        font-family: 'Elgoc'; font-size: 380px; color: $bordeaux;
        opacity: 0.1; transform: translate(-50%, -18px);
        pointer-events: none;
    }
    @include tablet {font-size: 50px;}
    @include tablet-sm {font-size: 40px;}
    @include mobile {font-size: 30px;}
}
.contentQuote__author {
    display: flex; align-self: flex-end; justify-content: center; align-items: center;
    width: 50%; min-width: 465px; margin-top: -38px;
    opacity: 0.6;
    @include tablet {align-self: center; margin: 0;}
}
.contentQuote__author-text {
    width: 220px; padding: 0 25px 60px 0;
    font-size: 18px; line-height: 24px; font-weight: 300;
    @include tablet {padding: 65px 0 40px; text-align: center;}
    @include mobile {padding: 65px 0 20px;}
}
.contentQuote__author-illustration {
    min-height: 200px;
    @include tablet {display: none;}
}
.contentQuote__infos {
    display: flex; align-items: center; justify-content: center; flex-direction: column;
    margin-top: -10px;
    max-width: 50%;
    text-align: center;
    @include tablet-sm {margin-top: 10px; text-align: center;}
}
.contentQuote__infos-title {
    font-family: 'Elgoc'; font-size: 24px;
}
.contentQuote__infos-link {
    display: inline-block;
    margin-top: 20px;
    color: $bordeaux; text-decoration: underline;
    cursor: pointer;
}