.button{
    display: inline-block; padding: 13px 25px 9px;
    cursor: pointer;
    @extend .baseTypo; font-size: 18px; line-height: 22px; font-weight: 500; color: #fff; text-align: center;
    box-shadow: 7px 8px 20px rgba(20, 2, 7, 0.3), -3px -3px 6px #ffffff;
    border-radius: 8px; border: none;
    background-image: linear-gradient(165deg, #bc295b 0%, $secondaryColor 100%);
    transition: all .15s ease-out; 

    &:hover,
    &:focus{
        background-image: linear-gradient(165deg, $secondaryColor 0%, #bc295b 100%);
    }
    &--grey {
        background: $greyPale; box-shadow: none;
        color: $black;
        &:hover,
        &:focus{
            background: $greyLight;
        }
    }
}
// .button-sm {font-size: 12px; padding: 6px 14px 3px;}

.btn__showMore{
    display: flex; align-items: center; justify-content: center;
    min-height: 45px; padding: 12px 24px;
    border: none; border-radius: 8px; background-color: rgba(0, 0, 0, 0.05);
    font-weight: 500;
}

.link{
    display: inline-block; text-decoration: underline; color: $secondaryColor;
    &:hover,
    &:focus{text-decoration: none;}
}

.button-none{border: none; background: transparent;}
.button-reset{margin: 0; padding: 0; border: none; background: transparent;}
.button-none:hover,
.button-none:focus,
.button-none:active,
.button-reset:hover,
.button-reset:focus,
.button-reset:active{border: none; background: transparent;}
::selection{}

.button-group {
    display: inline-flex;

    .button {border-radius: 0;}
    .button:first-child {border-radius: 5px 0 0 5px;}
    .button:last-child {border-radius: 0 5px 5px 0;}
}