.page-event--event-green{
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $event-green;}
	// .eventHero--past .eventHero__infos-date{color: $event-green;}
	.summary__list-item.is-active{border-color: $event-green;}
	.eventHero__infos-label{background: rgba(255,255,255,.2);}
	.chapterSubtitle__heading-number::before{border-color: $event-green;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($event-green,20%) 0%, $event-green 100%);
	}
	.quote__body {
		color: $event-green;
		&::before {color: $event-green;}
		&::after {color: $event-green;}
	}
}
.page-event--event-bordeaux{
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $event-bordeaux;}
	// .eventHero--past .eventHero__infos-date{color: $event-bordeaux;}
	.summary__list-item.is-active{border-color: $event-bordeaux;}
	.eventHero__infos-label{background: rgba(255,255,255,.2);}
	.chapterSubtitle__heading-number::before{border-color: $event-bordeaux;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($event-bordeaux,20%) 0%, $event-bordeaux 100%);
	}
	.quote__body {
		color: $event-bordeaux;
		&::before {color: $event-bordeaux;}
		&::after {color: $event-bordeaux;}
	}
}
.page-event--event-blue{
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $event-blue;}
	// .eventHero--past .eventHero__infos-date{color: $event-blue;}
	.summary__list-item.is-active{border-color: $event-blue;}
	.eventHero__infos-label{background: rgba(255,255,255,.2);}
	.chapterSubtitle__heading-number::before{border-color: $event-blue;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($event-blue,20%) 0%, $event-blue 100%);
	}

	.quote__body {
		color: $event-blue;
		&::before {color: $event-blue;}
		&::after {color: $event-blue;}
	}
}