
.feedItem__illustration.dark-blue{background-color: $study-dark-blue;}
.study--dark-blue{
	.section__note-text a,
	.wysiwyg sup strong,
	.wysiwyg strong sup,
	.wysiwyg a,
	.wysiwyg .link,
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $study-dark-blue;}
	.summary__list-item.is-active{border-color: $study-dark-blue;}
	.studyHero__infos-label{background: $study-dark-blue;}
	.chapterSubtitle__heading-number::before{border-color: $study-dark-blue;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($study-dark-blue,20%) 0%, $study-dark-blue 100%);
	}
}
.feedItem__illustration.light-blue{background-color: $study-light-blue;}
.study--light-blue{
	.section__note-text a,
	.wysiwyg sup strong,
	.wysiwyg strong sup,
	.wysiwyg a,
	.wysiwyg .link,
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $study-light-blue;}
	.summary__list-item.is-active{border-color: $study-light-blue;}
	.studyHero__infos-label{background: $study-light-blue;}
	.chapterSubtitle__heading-number::before{border-color: $study-light-blue;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($study-light-blue, 20%) 0%, $study-light-blue 100%);
	}
}
.feedItem__illustration.bordeaux{background-color: $study-bordeaux;}
.study--bordeaux{
	.section__note-text a,
	.wysiwyg sup strong,
	.wysiwyg strong sup,
	.wysiwyg a,
	.wysiwyg .link,
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $study-bordeaux;}
	.summary__list-item.is-active{border-color: $study-bordeaux;}
	.studyHero__infos-label{background: $study-bordeaux;}
	.chapterSubtitle__heading-number::before{border-color: $study-bordeaux;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($study-bordeaux, 20%) 0%, $study-bordeaux 100%);
	}
}
.feedItem__illustration.red{background-color: $study-red;}
.study--red{
	.section__note-text a,
	.wysiwyg sup strong,
	.wysiwyg strong sup,
	.wysiwyg a,
	.wysiwyg .link,
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: 	$study-red;}
	.summary__list-item.is-active{border-color: 	$study-red;}
	.studyHero__infos-label{background: $study-red;}
	.chapterSubtitle__heading-number::before{border-color: $study-red;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($study-red, 20%) 0%, $study-red 100%);
	}
}
.feedItem__illustration.pink{background-color: $study-pink;}
.study--pink{
	.section__note-text a,
	.wysiwyg sup strong,
	.wysiwyg strong sup,
	.wysiwyg a,
	.wysiwyg .link,
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $study-pink;}
	.summary__list-item.is-active{border-color: $study-pink;}
	.studyHero__infos-label{background: $study-pink;}
	.chapterSubtitle__heading-number::before{border-color: $study-pink;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($study-pink, 20%) 0%, $study-pink 100%);
	}
}
.feedItem__illustration.orange{background-color: $study-orange;}
.study--orange{
	.section__note-text a,
	.wysiwyg sup strong,
	.wysiwyg strong sup,
	.wysiwyg a,
	.wysiwyg .link,
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $study-orange;}
	.summary__list-item.is-active{border-color: $study-orange;}
	.studyHero__infos-label{background: $study-orange;}
	.chapterSubtitle__heading-number::before{border-color: $study-orange;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($study-orange, 20%) 0%, $study-orange 100%);
	}
}
.feedItem__illustration.dark-grey{background-color: $study-dark-grey;}
.study--dark-grey{
	.section__note-text a,
	.wysiwyg sup strong,
	.wysiwyg strong sup,
	.wysiwyg a,
	.wysiwyg .link,
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $study-dark-grey;}
	.summary__list-item.is-active{border-color: $study-dark-grey;}
	.studyHero__infos-label{background: $study-dark-grey;}
	.chapterSubtitle__heading-number::before{border-color: $study-dark-grey;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($study-dark-grey,20%) 0%, $study-dark-grey 100%);
	}
}
.feedItem__illustration.light-grey{background-color: $study-light-grey;}
.study--light-grey{
	.section__note-text a,
	.wysiwyg sup strong,
	.wysiwyg strong sup,
	.wysiwyg a,
	.wysiwyg .link,
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $study-light-grey;}
	.summary__list-item.is-active{border-color: $study-light-grey;}
	.studyHero__infos-label{background: $study-light-grey;}
	.chapterSubtitle__heading-number::before{border-color: $study-light-grey;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($study-light-grey,20%) 0%, $study-light-grey 100%);
	}
}
.feedItem__illustration.dark-brown{background-color: $study-dark-brown;}
.study--dark-brown{
	.section__note-text a,
	.wysiwyg sup strong,
	.wysiwyg strong sup,
	.wysiwyg a,
	.wysiwyg .link,
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $study-dark-brown;}
	.summary__list-item.is-active{border-color: $study-dark-brown;}
	.studyHero__infos-label{background: $study-dark-brown;}
	.chapterSubtitle__heading-number::before{border-color: $study-dark-brown;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($study-dark-brown,20%) 0%, $study-dark-brown 100%);
	}
}
.feedItem__illustration.light-brown{background-color: $study-light-brown;}
.study--light-brown{
	.section__note-text a,
	.wysiwyg sup strong,
	.wysiwyg strong sup,
	.wysiwyg a,
	.wysiwyg .link,
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $study-light-brown;}
	.summary__list-item.is-active{border-color: $study-light-brown;}
	.studyHero__infos-label{background: $study-light-brown;}
	.chapterSubtitle__heading-number::before{border-color: $study-light-brown;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($study-light-brown,20%) 0%, $study-light-brown 100%);
	}
}
.feedItem__illustration.dark-green{background-color: $study-dark-green;}
.study--dark-green{
	.section__note-text a,
	.wysiwyg sup strong,
	.wysiwyg strong sup,
	.wysiwyg a,
	.wysiwyg .link,
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $study-dark-green;}
	.summary__list-item.is-active{border-color: $study-dark-green;}
	.studyHero__infos-label{background: $study-dark-green;}
	.chapterSubtitle__heading-number::before{border-color: $study-dark-green;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($study-dark-green,20%) 0%, $study-dark-green 100%);
	}
}
.feedItem__illustration.light-green{background-color: $study-light-green;}
.study--light-green{
	.section__note-text a,
	.wysiwyg sup strong,
	.wysiwyg strong sup,
	.wysiwyg a,
	.wysiwyg .link,
	.summary__more,
	.summary__more-more,
	.credits__item-title,
	.chapterTitle__title,
	.chapterTitle__heading,
	.studyAlsoRead__study-surtitle,
	.introduction__title{color: $study-light-green;}
	.summary__list-item.is-active{border-color: $study-light-green;}
	.studyHero__infos-label{background: $study-light-green;}
	.chapterSubtitle__heading-number::before{border-color: $study-light-green;}
	.studyHero__study-button{
		background-image: linear-gradient(165deg, lighten($study-light-green,20%) 0%, $study-light-green 100%);
	}
}