/* Constants */
	$pi: 3.14159265359;

/* Colors */
	$white: #ffffff;
	$black: #000000;
	
	$greyDark: #1a1713;
	$grey: #999999;
	$greyLight: #e8eae4;
	$greyPale: #f2f2f2;
	
	$bordeaux: #87122e;
	$green: #5c5b2e;

	$textColor: $greyDark;
	$secondaryColor: $bordeaux;

	$study-dark-blue   : #303869;
	$study-light-blue  : #5b779a;
	$study-bordeaux    : #6c2324;
	$study-red         : #88233b;
	$study-pink        : #c57f7c;
	$study-orange      : #b5622e;
	$study-dark-grey   : #4f4c58;
	$study-light-grey  : #898989;
	$study-dark-brown  : #4d2a1a;
	$study-light-brown : #89674b;
	$study-dark-green  : #4c5e51;
	$study-light-green : #838b3e;

	$event-green : #5c5b2e;
	$event-bordeaux : #540b1d;
	$event-blue : #5b779a;

	$social-facebook: #1877f2;
	$social-twitter: #1da1f2;
	$social-linkedin: #0077b5;
	$social-instagram: #e4405f;
	

/* Sizes */
	$headerHeight: 175px;
	$headerHeight-mobile: 125px;

/***** Scrollbar *****/
@mixin scrollbar {
    /* Scrollbar */
    &::-webkit-scrollbar {
        width: 10px; height: 10px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: $greyPale; 
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 2px solid $greyPale;
        background: $grey; border-radius: 6px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $grey; 
    }
}