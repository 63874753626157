/* EVENT LIST */
.eventList {
    padding: 35px 0;
    @include tablet-sm {padding: 15px 0;}
}
.eventList__more {
    display: flex; justify-content: center; align-items: center;
    padding-top: 35px;
}
.eventList__more-button {
    display: flex; align-items: center; justify-content: center;
    min-height: 45px; padding: 12px 24px;
    border: none; border-radius: 8px; background-color: rgba(0, 0, 0, 0.05);
    font-weight: 500;
}