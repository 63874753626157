/***********************************
*** GOOGLE MAP TERRORIST ATTACKS ***
************************************/
.gmapLogo {
    position: absolute; top: 10px; left: 10px; z-index: 501;
    background: #ffffff;
    padding: 10px;
}
.gmapLogo__img {max-width: 130px; margin: 0; display: block;}

.gmapLinks {
    float: right; padding: 10px 0;
}
.gmapLink {
    box-shadow: none;
}

.gmapContainer {position: relative; margin-top: 10px;}

.gmapLoader,
#googlemap-terrorist-attacks {
    width: 100%; height: 80vh;
}

.gm-ui-hover-effect > img {
    width: 20px !important; height: 20px !important;
    margin: 9px 7px 5px 1px !important;
}

.gmapAttacks__infoWindow {max-width: 300px;}
.gmapAttacks__infoWindow__title {margin-bottom: 10px;}
.gmapAttacks__infoWindow__list {max-height: 200px; text-align: left;}
.gmapAttacks__infoWindow__listItem + .gmapAttacks__infoWindow__listItem {
    margin-top: 10px; padding-top: 10px;
    border-top: 1px solid #d3d3d3;
}
.gmapAttacks__infoWindow__listItem__title {
    margin-bottom: 5px;
    font-weight: bold; text-align: center;
}
.gmapLoader {
    position: absolute; bottom: 0; z-index: 500;
    background: rgba(0, 0, 0, 0.8);
}
.gmapLoader__svg {
    position: absolute; top: 50%; left: 50%;
    margin: -65px 0 0 -65px;
    fill: #1b96d1;
    animation: loader 0.7s infinite linear;
}
@keyframes loader {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}


.totalAttacksCount {
    font-size: 17px;
    margin: 14px 0 10px 15px;
}

.gmapControl__container {
    text-align: left; padding: 0 15px;
}
.gmapControl {
    display: inline-block; vertical-align: middle;
}
.gmapControl__title {
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 15px;
}

.gmapControl__single {
    position: relative; display: none;
    width: 90%; margin: 0 auto;
}
.gmapControl__single__mark {
    position: absolute;
    width: 1px; height: 10px;
    bottom: 0;
    background: #323232;
}
.gmapControl__single__input {
    width: 100%; margin-bottom: 13px;
}
.gmapControl__single__value {
    font-size: 17px;
}
.gmapControl__single__marks {
    position: relative;
    width: 99.1%;
    margin: 0 0 0 0.6%;
}

.gmapControl__range__btn {transition: all .2s ease-out; margin-top: 10px; margin-bottom: 10px; box-shadow: none; border: 1px solid $bordeaux;}
.gmapControl__range__btn.active {
    color: $bordeaux;
    background-color: $white;
    background-image: none;
}

.gmapControl__select {
    max-width: 220px; display: inline-block;
    margin: 17px 15px 0 0; padding: 3px 5px;
    vertical-align: top;
    color: #000000;
    font-size: 22px;
    border: none;
    border-bottom: 1px solid #000;
    background-color: transparent;
    border-radius: 0;
}

/* devices ≤ 890px */
@media (max-width:890px){
    .gmapControl {display: block; margin: 10px auto; text-align: center;}
    .gmapLinks {float: none; text-align: center;}
    .gmapControl__select {display: block; margin: 0 auto 5px;}
    .gmapControl__range__btn {margin: 5px;}
}