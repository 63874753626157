.feedPush{
    flex-grow: 1;
    position: relative; max-height: 640px; margin: 40px 0; padding: 70px 12.5% 12.5%; color: #fff;
    background-size: cover; background-position: center;
}
.feedPush--data {
    &:before {
        content:'';
        position: absolute; top: 0; right: 0; bottom: 0; left: 0;
        background-image: linear-gradient(to top, rgba(255, 57, 59, 0) 0%, #87122e 100%);
    }
}
.feedPush__content{position: relative; z-index: 1;}
.feedPush__title{
    margin-bottom: 35px;
    span{display: inline-block;}
    @extend .titleTypo; font-size: 40px; line-height: 1.1; font-weight: 400;
}
.feedPush__text{margin-bottom: 40px; font-size: 18px; font-weight: 400; line-height: 24px;}
.feedPush__ctaText{
    display: inline-block; padding: 15px 18px 11px;
    font-size: 19px; font-weight: 500; color: $bordeaux;
    box-shadow: 7px 8px 20px rgba(20, 2, 7, 0.3); border-radius: 4px; background-color: #ffffff;
    transition: 0.4s cubic-bezier(0.4,0,.1,1); -webkit-mask-image: -webkit-radial-gradient(white, black); backface-visibility: hidden;
    transform: scale(0.94) translateX(-3%);
    &:hover {transform: scale(1) translateX(-3%);}
}

.feedPush.--contentXL {
    max-height: none;
    padding: 12.5%;

    .feedPush__title{
        font-size: 30px;
    }
}
.feedPush.--noMarginTop {
    margin-top: 0;
}
.feedPush.--noMarginBot {
    margin-bottom: 0;
}