.studyHighlights {
    padding: 35px 0;
}
.studyHighlights__container {
    // min-height: 920px;
    min-height: 680px;
}
.studyHighlights__text {
    display: flex; align-items: center;
    height: 100%;
}
.studyHighlights__text-content {
    padding-bottom: 20px;
}
.studyHighlights__infos {
    display: flex; align-items: center;
    margin-bottom: 25px;
    text-transform: uppercase;
    span {
        margin-right: 40px;
    }
}
.studyHighlights__infos-label {
    color: $bordeaux; font-weight: 500;
}
.studyHighlights__title {font-size: 74px; line-height: 78px;}
.studyHighlights__subtitle {
    margin-top: 16px;
    line-height: 24px;
}
.studyHighlights__intro {
    display: block;
    width: 100%; max-width: 460px; margin-top: 40px;
    line-height: 24px;
}
.studyHighlights__links {
    display: flex; align-items: center;
    margin-top: 40px;
}
.studyHighlights__links-study {

}
.studyHighlights__links-all {
    display: inline-block; margin-left: 35px;
}

.studyHighlights__illustration {
    display: flex; align-items: center;
    height: 100%;
    background-color: $greyPale; background-size: cover; background-repeat: no-repeat; background-position: center;

}
.studyHighlights__illustration-content {
    display: flex; justify-content: center;
    .studyCover__container {
        overflow: hidden; backface-visibility: hidden;
        transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.84); box-shadow: 0 22px 44px rgba(26,23,19,0.4);
    }
}
.studyHighlights__illustration-image{
    max-width: 100%; max-height: 100%;
    
}

@media (max-width : $mq-tablet) {
    .studyHighlights__container{min-height: 580px;}
    .studyHighlights__title {font-size: 54px; line-height: 58px;}
    .studyHighlights__intro{margin-top: 30px;}
    .studyHighlights__links{margin-top: 30px;}
    .studyHighlights__illustration-content {padding: 30px 0;}
}
@media (max-width : $mq-tablet-sm) {
    .studyHighlights__illustration-content {height: 440px;}
}
@media (max-width : $mq-mobile) {
    .studyHighlights__title {font-size: 54px; line-height: 58px;}
}
@media (max-width : $mq-mobile-sm) {
    .studyHighlights__title {font-size: 44px; line-height: 48px;}
}