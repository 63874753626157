.wysiwyg {
    strong{font-weight: bold;}
    i{font-style: italic;}
    u{text-decoration: underline;}
    &::before, &::after {content: ""; display: block; clear: both;}

    // h1 {@extend .heading-1;}
    // h2 {@extend .heading-2;}
    // h3 {@extend .heading-3;}
    // h4 {@extend .heading-4;}
    // h5{@extend .chapo;}
    // h6{@extend .chapo;}
    h1, h2, h3, h4, h5, h6 {margin-bottom: 0.5em;}
    p{margin-bottom: 1rem;}
    p + h1, p + h2, p + h3, p + h4, p + h5, p + h6 {margin-top: 2rem;}
    a {
        display: inline;
        text-decoration: underline;
        color: $secondaryColor;
        &:hover {text-decoration: none;}
    }
    em {font-style: italic;}
    ol, ul{padding-left: 20px; margin-bottom: 1em;}
    ol,
    ol li{
        list-style:decimal;
    }
    ul,
    ul li{list-style:disc;}
    ul.tirets-ul,
    ul.tirets-ul li{list-style:none;}
    ul.tirets-ul li:before{content: "- ";}

    blockquote{
        //p{ font-family: Elgoc; font-size: 1.2em;}
        //&:before{content: "“"; margin-top: .6em; margin-bottom: -0.4em; }
        //&:after{ content: "”";}
        //&:before,
        //&:after{position: relative; z-index: -1; display: block; font-family: Elgoc; color: rgb(135, 18, 46); opacity: 0.5; font-weight: bold; font-size: 2em; line-height: .5em; letter-spacing: -0.5em;}
        //// @extend %EuclidFlex;
        //
        //
        //@include tablet {
        //    font-size: 18em; line-height: 28em;
        //}
        //@include mobile {
        //    font-size: 18em; line-height: 26em;
        //}
    }

    table td{
        padding: 15px 12px;
        border: 1px solid grey;
        font-size: 1em; line-height: 1em;
        @include tablet {padding: 13px 10px;}
    }

    img {
        max-width: 100%; height: auto;
    }
    img.centered {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .aligncenter{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    img.alignright {
        padding: 4px;
        margin: 0 0 2px 7px;
        display: inline;
    }

    img.alignleft {
        padding: 4px;
        margin: 0 7px 2px 0;
        display: inline;
    }

    .alignright {
        float: right;
    }

    .alignleft {
        float: left;
    }

    .twitter-tweet {
        margin: 0 auto;
    }


    @include mobile {
        table{display: block; overflow: auto;}
    }
}

.pageDefault .wysiwyg {
    h2, h3, h4, h5, h6 {font-family: 'Elgoc';}
    h2 {
        color: $bordeaux; font-size: 42px; line-height: 42px;
        @include mobile {
            font-size: 36px; line-height: 36px;
        }
    }
    h3 {
        color: $grey; font-size: 36px; line-height: 36px;
        @include mobile {
            font-size: 26px; line-height: 26px;
        }
    }
    h4 {
        color: $bordeaux; font-size: 26px; line-height: 26px;
        font-weight: bold;
        @include mobile {
            font-size: 26px; line-height: 26px;
        }
    }
    h5 {
        color: $grey; font-size: 26px; line-height: 26px;
        font-weight: bold;
        @include mobile {
            font-size: 24px; line-height: 24px;
        }
    }
    h6 {
        color: $grey; font-size: 24px; line-height: 24px;
        font-weight: bold;
        @include mobile {
            font-size: 24px; line-height: 24px;
        }
    }
}