.section__note {
    
    margin-top: 35px;
    font-size: 16px; line-height: 20px; color: $grey;
    p {display: inline;}
    @include mobile {padding-left: 30%;}
}
.section__note-title {color: $greyDark;}
.section__note-item {
    position: relative;
    margin-top: 7px; padding-right: 7px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    &.is-active {
        display: block;
    }
}
.section__note-text {
    display: inline;
    a {
        color: $bordeaux; text-decoration: underline; cursor: pointer;
        &:hover {text-decoration: none;}
    }
}
.section__note-button {
    position: absolute; right: 0; bottom: 0;
    display: none; padding-left: 7px;
    font-weight: 500; color: $greyDark;
    cursor: pointer;
    .section__note-item.is-active & {
        right: auto;
    }
    .section__note-item.is-collapsed & {
        display: inline;
    }
}
.section__note-button-more {
    .section__note-item.is-active & {display: none;}
}
.section__note-button-less {
    display: none;
    .section__note-item.is-active & {display: inline;}
}

.note__backToText{position: relative; margin-left: 1px; padding: 1px;
    &:focus{outline: none;}
    &:focus:before{
        content: ""; position: absolute; top: 0; right: 0px; bottom: 3px; left: 0px;
        outline: 1px solid;
    }
}
.note__goToNote{position: relative;
    &:focus{outline: none;}
    &:focus:before{
        content: ""; position: absolute; top: -5px; right: -1px; bottom: 0px; left: -1px;
        outline: 1px solid;
    }
}