.photoLibrary {
    position: relative; z-index: 1;
    padding: 65px 0;
}
.photoLibrary__container {
    padding: 95px 110px 105px; overflow: hidden;
    background-color: #2a2a2a;
    color: $white;
}
.photoLibrary__content {
    
}
.photoLibrary__infos {
    display: flex; flex-direction: column; justify-content: space-between;
    height: 100%;
}
.photoLibrary__infos-content {
    padding-right: 25px;
}
.photoLibrary__title {
    display: block; margin-bottom: 30px;
    font-family: 'Elgoc'; font-size: 34px;
}
.photoLibrary__text {
    display: none;
    line-height: 24px;
    &.is-active {display: block;}
}
.photoLibrary__sharer{
    display: none;
    margin-bottom: 30px;
    &.is-active {display: block;}
}
.photoLibrary__infos-footer {
    .sharer__icon,
    .icon-close{fill: #fff;}

}
.photoLibrary__infos-count {
    font-size: 72px; font-family: 'Elgoc'; 
    // weird line-height fix trics
    line-height: 32px; padding-top: 40px;
}
.photoLibrary__infos-count-total {
    opacity: 0.2;
}

.photoLibrary__slider {

}
.photoLibrary__slide-container {
    position: relative;
    padding-bottom: 66.67%;
    background-color: rgba(0,0,0,0.15);
    overflow: hidden;
    vertical-align: top;
}
.photoLibrary__slide {
    position: absolute; left: 0; top: 0;
    display: flex; justify-content: center; align-items: center;
    width: 100%; height: 100%;
}
.photoLibrary__slide-image {
    width: auto; height: auto; max-width: 100%; max-height: 100%;
}

.photoLibrary__controls {
    display: flex; flex-direction: column; justify-content: flex-end; align-items: center;
    height: 100%;
}
.photoLibrary__controls-next, .photoLibrary__controls-prev {
    position: relative;
    display: flex; justify-content: center; align-items: center;
    width: 80px; height: 80px; margin-top: 14px;
    border-radius: 50%;
    background-color: $greyDark;
    box-shadow: 17px 10px 10px rgba(0, 0, 0, 0.3), -6px -6px 20px rgba(255, 255, 255, 0.05);
    cursor: pointer; transition: 0.4s;
    &:hover {
        background-color: #13100e;
    }
    .icon{fill: #fff;}
}

.photoLibrary__controls-timer {
    position: absolute; top: 0; right: 0; bottom: 0; left: 0;
    width: 100%; height: 100%;
    transform: rotate(-90deg);
    pointer-events: none;
}
.photoLibrary__controls-timer-circle {
    fill: none; stroke: $white; stroke-width: 2px;
    stroke-dasharray: 80 * $pi * 1px;
    stroke-dashoffset: 80 * $pi * 1px;

    &.is-animating {
        stroke-dashoffset: 0;
        transition: 5s;
    }
    &.is-reseting {
        opacity: 0;
        // stroke-dashoffset: 80 * $pi * -1px;
        transition: 0.5s;
    }
}

@media (max-width : $mq-tablet-sm) {
    .photoLibrary{padding: 40px 0;}
    .photoLibrary__container{padding: 50px 30px;}
    .photoLibrary__infos-count{margin-top: 25px; font-size: 43px; line-height: 20px; padding-top: 20px;}
    .photoLibrary__controls{flex-direction: row; justify-content: center;}
    .photoLibrary__controls-next {order: 2;}
    .photoLibrary__controls-next, 
    .photoLibrary__controls-prev{width: 45px; height: 45px; margin: 10px; font-size: 14px;}

    .photoLibrary__controls-timer-circle {
        // stroke-dasharray: 80 * $pi * 1px;
        // stroke-dashoffset: 80 * $pi * 1px;
    }
}