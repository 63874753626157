.don__header{
    position: relative;
    padding: 60px 10px;
    text-align: center; color: #fff;
    background: url('../../images/banner-donations.jpg') no-repeat center;
    background-size: cover;
    &:before{
        content: ""; position: absolute; top: 0; right: 0; bottom: 0; left: 0;
        background: url('../../images/pattern-fondapol-tv.png') rgba(0, 0, 0, 0.5) repeat;
    }
    @include mobile {padding: 45px 10px 55px;}
}
.don__headerContainer{z-index: 1; position: relative;}
.don__inputContainer{
    font-size: 30px; line-height: 1; font-weight: 500; text-transform: uppercase;
}
.don__input{
    display: inline-block; width: auto; min-width: 40px; height: 35px; margin-top: -.25em; padding: .25em 0 0;
    vertical-align: top;
    font-size: 30px; line-height: 35px; color: #1b96d1; text-align: center;
    border: solid 1px #FFFFFF; background-color: rgba(255, 255, 255, 0.75);
}
.don__submit{
    display: inline-block; padding: 0.5em .5em .25em; margin-top: 15px;
    font-size: 20px; line-height: 20px;
    text-align: center; text-decoration: none; text-transform: uppercase;
    border-style: solid; border-width: 2px; background: none;
}
.don__title{
    position: relative; font-size: 55px; line-height: 65px; margin-bottom: 45px; padding-bottom: 30px;
    &:after {
        content: '';
        position: absolute; bottom: 5px; left: 50%;
        display: block; width: 50px; height: 3px;
        transform: translateX(-50%);
        background-color: #1b96d1;
    }
    @include mobile {
        margin-bottom: 20px; padding-bottom: 20px;
        font-size: 50px;
    }
}
.don__title,
.don__reduc{@extend .titleTypo;}
.don__reduc{
    margin-top: 18px;
    font-size: 22px; line-height: 1; font-style: italic;
}
.don__montantreduc{color: #1b96d1;}
.don__subtitle{
    margin-top: 40px; font-size: 30px; line-height: 30px; font-weight: 500;
    @include mobile {font-size: 20px; line-height: 22px;}
}
.don__dib{display: inline-block;}