.popin {
    position: fixed; left: 0; top: 0; z-index: 100;
    width: 0; height: 0;
    overflow: hidden;
    opacity: 0; transition: opacity 0.2s 0.2s;
    pointer-events: none;

    &.is-active {
        width: 100vw; height: 100vh;
        opacity: 1; transition: opacity 0.2s;
        pointer-events: all;
    }
}
.popin__underlay {
    position: absolute; left: 0; top: 0; z-index: 1;
    width: 100%; height: 100%;
    background-color: rgba(26, 23, 19, 0.8);
}
.popin__container {
    position: relative; z-index: 2;
    display: flex; justify-content: center; align-items: center;
    height: 0;
    pointer-events: none;
    .popin.is-active & > * {pointer-events: all;}
}
.is-active .popin__container{height: 100vh;}

/* Newsletter popin */
.newsletterPopin {
    position: relative;
    display: flex;
    width: 100%; max-width: 1270px; min-height: 572px;
    background: $white;
    transform-origin: center; transform: scale(0.8); opacity: 0.8; transition: 0.4s;
    .popin.is-active & {
        transform: scale(1); opacity: 1;
    }
}
.newsletterPopin__illustration {
    position: relative;
    display: flex; justify-content: center; align-items: center; flex-direction: column;
    width: 50%; padding: 50px;
    background: url('../../images/newsletter-popin-bg.jpg'); background-size: cover; background-position: center;
    &::before {
        content: ""; position: absolute; left: 0; top: 0; z-index: 1;
        width: 100%; height: 100%;
        background-image: linear-gradient(-39deg, rgba(255, 57, 59, 0) 0%, rgba(253, 56, 58, 0.01) 31%, #87122e 100%);
    }
    @include mobile {padding: 20px;}
}
.newsletterPopin__illustration-label {
    position: relative; z-index: 2;
    display: block;
    font-family: 'Elgoc'; font-size: 67px; line-height: 72px; color: $white;
    &:nth-child(1) {transform: translateX(-32px);}
    &:nth-child(2) {transform: translateX(54px);}
    &:nth-child(3) {transform: translateX(-32px);}
    &:nth-child(n) {@include mobile {transform: none;}}
    @include tablet-sm {font-size: 50px; line-height: 52px;}
    @include mobile {font-size: 36px; line-height: 40px;}
}

.newsletterPopin__content {
    display: flex; justify-content: flex-end; align-items: center; flex-direction: column;
    width: 50%; padding: 50px 40px 50px 60px;
    @include tablet-sm {padding: 20px 30px;}
}
.newsletterPopin__close {
    position: absolute; right: 30px; top: 30px; z-index: 2;
    cursor: pointer;
    .icon {width: 17px; height: 17px;}
    @include tablet {.icon {fill: $white;}}
}
.newsletterPopin__form {
    flex: 1; display: flex; justify-content: center; flex-direction: column;
    width: 100%; padding-bottom: 20px;
}
.newsletterPopin__form-label {
    margin-bottom: 30px;
    @include mobile {margin-bottom: 20px;}
}
.newsletterPopin__form-input {
    width: 100%; max-width: 430px;
    .gform_wrapper & {
        font-family: 'Elgoc' !important;
        font-size: 26px !important;
        line-height: 37px;
    }
}
.newsletterPopin__form-footer {
    display: flex; align-items: center; flex-wrap: wrap;
    margin-top: 25px;
    button {
        margin-top: 15px;
    }
    @include mobile {margin-top: 10px;}
}
.newsletterPopin__form-submit {margin-right: 24px;}
.newsletterPopin__socials {
    display: flex; align-items: center; justify-content: flex-end; flex-wrap: wrap;
    width: 100%;

    &--facebook .icon {fill: $social-facebook;}
    &--twitter .icon {fill: $social-twitter;}
    &--linkedin .icon {fill: $social-linkedin;}
    &--instagram .icon {fill: $social-instagram;}
    @include mobile {justify-content: center;}
}
.newsletterPopin__socials-label {
    display: inline-block; padding-right: 50px;
    font-weight: 500;
    @include mobile {
        width: 100%; padding: 0; margin-bottom: 10px; 
        text-align: center;
    }
}
.newsletterPopin__socials-link {
    margin-right: 30px;
    &:last-child {margin-right: 0;}
    .icon {
        width: 24px; height: 24px;
        vertical-align: top;
    }
    @include mobile {
        margin-right: 20px;
    }
}

//* Widget
.newsletterPopin__widget {
    position: relative;
    margin-left: -15px;
    iframe {
        height: 154px !important;
    }
}
.newsletterPopin__widget-cancel {
    // position: absolute; bottom: 56px; left: 190px;
    text-align: center;
}

/* Comment popin */
.commentPopin {
    position: relative;
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    width: 900px; max-width: 90%; padding: 40px 50px;
    background: $white;
}
.commentPopin__close {
    position: absolute; right: 30px; top: 30px;
    cursor: pointer;
}
.commentPopin__title {
    margin-bottom: 40px;
    color: $bordeaux; font-size: 46px;
}
.commentPopin__name {
    width: 100%; margin-bottom: 20px;
}
.commentPopin__email {
    width: 100%; margin-bottom: 20px;
}
.commentPopin__message {
    width: 100%; min-height: 200px; margin-bottom: 20px;
}
.commentPopin__submit {

}

