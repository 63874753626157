.feedIttem__twitterTimeline{margin: -10px 0 40px;}
.feedItem {
    display: block;
    & + & {margin-top: 40px;}
    &.feedItem--small {
        @include tablet-sm {
            display: flex; align-items: flex-start;
        }
    }
    &.is-hidden {
        position: absolute;
        opacity: 0; // bug firefox "display: none;" instead
        // display: none;
        pointer-events: none;
    }
    
}
.feedItem__illustration {
    position: relative;
    flex-shrink: 0;
    display: block; width: 100%; padding-bottom: 63%; margin-bottom: 25px;
    background-color: $greyPale; background-size: cover; background-position: center; background-repeat: no-repeat;
    .feedItem--small & {
        padding-bottom: 96%;
        @include tablet-sm {
            width: 110px; padding-bottom: 110px; margin-bottom: 0;
        }
    }
}
.feedItem__image {
    position: absolute; top: 50%; left: 50%;
    height: 80%;
    transform: translate(-50%, -50%); transition: transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.84);
    backface-visibility: hidden;
}
.feedItem__image-border {
    position: absolute; left: 0; top: 0; z-index: 2;
    width: 12px; height: 100%;
    background-color: rgba(0, 0, 0, 0.2); 
    backface-visibility: hidden; transform: translate3d(0,0,0);
    &::after {
        content: ""; position: absolute; right: 0; top: 0;
        height: 100%;
        border-left: 1px solid rgba(255,255,255,0.2);
        border-right: 1px solid rgba(0,0,0,0.5);
        transform: translate3d(0,0,0) scaleX(1.2); backface-visibility: hidden;
    }
    .feedItem--small & {width: 8px;}
}
.feedItem__image-cover {
    height: 100%; max-width: max-content;
    box-shadow: 0 22px 44px rgba(26, 23, 19, 0.4);
}

.feedItem__illustration + .feedItem__content {
    .feedItem--small & {
        @include tablet-sm {
            padding: 10px 0 0 20px;
        }
    }
}
.feedItem__infos {
    display: flex; flex-wrap: wrap;
}
.feedItem__type {
    display: block; margin-bottom: 10px; margin-right: 18px;
    font-size: 16px; color: $bordeaux; font-weight: 500; text-transform: uppercase;
    @include tablet-sm {font-size: 14px;}
    .feedItem--small & {margin-bottom: 4px;}
}
.feedItem__logo {
    max-height: 40px; max-width: 120px;
}
.feedItem__author {
    font-size: 16px; color: $grey; text-transform: uppercase;
    @include tablet-sm {font-size: 14px;}
}
.feedItem__date {
    margin-right: 18px;
    font-size: 16px; color: $grey; text-transform: uppercase;
    @include tablet-sm {font-size: 14px;}
}
.feedItem__title {
    display: block;
    font-family: 'Elgoc'; font-size: 34px; line-height: 30px;
    @include tablet-sm {font-size: 18px; line-height: 24px;}
    .feedItem--small & {
        font-size: 24px;
        @include tablet-sm {font-size: 18px; line-height: 24px;}
    }
}
.feedItem__intro {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    margin-top: 10px;
    line-height: 24px; color: $grey;
    @include tablet-sm {font-size: 14px; line-height: 18px;}
    .feedItem--small & {
        @include tablet-sm {display: none;}
    }
}

.feedIttem__twitterTimeline {
    flex-grow: 1;
    height: 600px; max-height: 900px;
    @include tablet {height: 600px;}
    iframe {height: 100% !important;}
}