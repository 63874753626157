.studyAlsoRead {
    padding: 35px 0;
}
.studyAlsoRead__container {
    padding: 65px 0 70px;
    border: 1px solid $greyLight;
    border-width: 1px 0;
    @include tablet {padding: 40px 0;}
}
.studyAlsoRead__controls {
    display: flex; flex-direction: column; justify-content: flex-end; align-items: center;
    height: 100%; padding-bottom: 12px;
    color: $greyDark;
    @include tablet {
        flex-direction: row; justify-content: flex-start;
    }
    @include mobile {
        justify-content: space-between;
    }
}

@include large {
    .studyAlsoRead__controls-less_than_4{display: none;}
}

@include medium {
    .studyAlsoRead__controls-less_than_3{display: none;}
}
@include tablet-sm {
    .studyAlsoRead__controls-less_than_3{display: block;}
    .studyAlsoRead__controls-less_than_2{display: none;}
}
.studyAlsoRead__controls-title {
    flex-grow: 1; display: flex; justify-content: center; align-items: center;
    width: 100%; max-width: 115px; margin-bottom: 25px;
    font-family: 'Elgoc'; font-size: 34px; line-height: 42px; text-align: center;
    @include tablet {
        justify-content: flex-start;
        max-width: max-content; margin: 0 20px 0 0;
    }
    @include mobile {
        max-width: 100%; margin: 0;
    }
}
.studyAlsoRead__controls-arrows {
    flex-shrink: 0;
}
.studyAlsoRead__controls-prev {
    padding: 0 10px;
    cursor: pointer; transition: 0.3s;
    &.is-disabled {
        opacity: 0.4;
        pointer-events: none;
    }
    .icon {
        width: 25px; height: 25px;
        fill: $grey;
    }
}
.studyAlsoRead__controls-next {
    padding: 0 0 0 10px;
    cursor: pointer; transition: 0.3s;
    &.is-disabled {
        opacity: 0.4;
        pointer-events: none
    }
    .icon {
        width: 25px; height: 25px;
        fill: $grey;
    }
}
.studyAlsoRead__slider {
    margin-right: -25px;
    @include mobile {margin-left: -16px;}
}
.studyAlsoRead__study {
    display: flex; align-items: flex-start;
    padding: 16px 25px 16px 16px;
    @include medium {padding: 16px;}
    @include mobile {padding: 16px 0 16px 16px;}
    .studyCover__container {
        flex-shrink: 0;
        width: 130px;
        box-shadow: 0 4px 12px rgba(26, 23, 19, 0.35);
        transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.84);
        backface-visibility: hidden;
        
        @include mobile {width: 110px;}
    }
    &:hover .studyCover__container {
        transform: scale(1.05);
    }
}
.studyAlsoRead__study-image {
    width: 100%;
}

.studyAlsoRead__study-infos {
    flex-grow: 1;
    padding-left: 30px;
    @include mobile {padding: 20px 12px 0 12px;}
}
.studyAlsoRead__study-surtitle {
    margin-bottom: 4px;
    font-size: 16px; font-weight: 500; text-transform: uppercase; color: $bordeaux;
    @include mobile {font-size: 14px;}
}
.studyAlsoRead__study-title {
    font-size: 24px; line-height: 30px; font-family: 'Elgoc';
    display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; overflow: hidden;
    @include mobile {font-size: 18px; line-height: 24px;}
}