.sharer {
    display: flex; align-items: center;

}
.sharer__content {
    position: relative;

}
.sharer__button {
    height: 0;
    text-decoration: underline; cursor: pointer;
    transition: 0.3s 0.3s;
    .sharer.is-active & {
        opacity: 0;
        pointer-events: none;
        transition: 0.3s;
    }
}
.sharer__text {
    line-height: 28px;
}

.sharer__list {
    display: flex; align-items: center;
    max-width: 0;
    overflow: hidden;
    pointer-events: none;
    transition: 0.3s;
    .sharer.is-active & {
        max-width: 200px;
        pointer-events: all;
        transition: 0.3s 0.2s;
    }
}
.sharer__icon {
    margin-right: 12px;
    cursor: pointer;
    input {
        position: absolute; top: 100%;
        opacity: 0; padding: 0; border: 0;
        pointer-events: none;
    }
    .icon {pointer-events: none;}
    .icon-link {
        width: 17px; height: 17px;
    }
    .icon-facebook2 {
        width: 16px; height: 16px;
        fill: $social-facebook;
    }
    .icon-twitter {
        width: 17px; height: 17px; padding-top: 2px;
        fill: $social-twitter;
    }
    .icon-linkedin2 {
        width: 18px; height: 18px;
        fill: $social-linkedin;
    }
}
.sharer__icon-tooltip {
    position: absolute; top: calc(100% + 5px);
    display: inline-block; width: max-content; padding: 6px 8px 2px;
    background-color: $greyDark; border-radius: 4px;
    color: $white; font-size: 16px;
    opacity: 0; pointer-events: none; transition: 0.1s; transform: translateX(-30px);
    .sharer__icon:hover > & {opacity: 0.9;}
}
.sharer__close {
    padding: 5px; margin-left: 5px;
    cursor: pointer;
    .icon-close {
        width: 10px; height: 10px;
    }
}