/* STUDY LIST */
.studyList {
    padding: 35px 0;
    @include tablet-sm {padding: 15px 0;}
}
.studyList__more {
    display: flex; justify-content: center; align-items: center;
    padding-top: 35px;
}
.studyList__more-button {
    position: relative;
    display: flex; align-items: center; justify-content: center;
    min-height: 45px; padding: 12px 24px;
    font-weight: 500; color: #fff;
    background-image: linear-gradient(165deg, #bc295b 0%, #87122e 100%);
    border: none; border-radius: 8px; 
    // background-color: rgba(0, 0, 0, 0.05);
    // transition: 0.2s 0.1s;
    &.active,
    &:hover,
    &:focus{background: #87122e;}
    .icon {
        position: absolute; right: 14px; top: 13px;
        height: 18px; width: 18px; margin-left: 12px; margin-bottom: 1px;
        fill: #fff;
        animation: ANIM_LOADING 1s linear infinite;
        opacity: 0; transition: 0.1s;
    }
    &.is-loading {
        padding-right: 44px;
        transition: 0.2s;
        .icon {opacity: 1; transition: 0.1s 0.1s;}
    }
}
.studyList__more--mobile{display: none;}
@include tablet-sm{
    .studyList__more{display: none;}
    .studyList__more--mobile{display: flex;}
    .studyList__cell-hideMobile{display: none;}
    .firstLoadDone .studyList__cell-hideMobile{display: block;}
}
button.show-more-button {
    position: relative;
    display: flex; align-items: center; justify-content: center;
    min-height: 45px; padding: 12px 24px 10px;
    font-weight: 500; color: #fff;
    background-image: linear-gradient(165deg, #bc295b 0%, #87122e 100%);
    border: none; border-radius: 8px; 
    // background-color: rgba(0, 0, 0, 0.05);
    // transition: 0.2s 0.1s;
    &.active,
    &:hover,
    &:focus{background: #87122e;}
    .icon {
        position: absolute; right: 14px; top: 13px;
        height: 18px; width: 18px; margin-left: 12px; margin-bottom: 1px;
        fill: #fff;
        animation: ANIM_LOADING 1s linear infinite;
        opacity: 0; transition: 0.1s;
    }
    &.is-loading {
        padding-right: 44px;
        transition: 0.2s;
        .icon {opacity: 1; transition: 0.1s 0.1s;}
    }
    &.is-error {border: 1px solid $bordeaux; color: $bordeaux;}
}