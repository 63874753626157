.presentation{
    padding: 60px 0;
    @include tablet-sm {
        padding: 30px 0;
        .textCol {padding-bottom: 0;}
    }
}
.presentation__title{
    margin: 60px 0;
    // font-size: 84px; font-weight: 400; font-family: 'Elgoc'; color: $bordeaux;
    @include tablet {
        max-width: 100%; margin: 0;
        // font-size : 74px; line-height: 80px;
    }
    // @include mobile {font-size : 64px; line-height: 70px;}
}
.presentationQuote {
    display: flex; justify-content: center; align-items: center; flex-direction: column;
}
.presentationQuote__heading {
    position: relative;
    display: flex; justify-content: center; align-items: center;
    font-family: 'Elgoc'; font-size: 42px; font-weight: 400;
    padding: 65px 0 90px;
    &::before {
        content: "“"; position: absolute; left: 50%; top: 0;
        font-family: 'Elgoc'; font-size: 280px; color: $bordeaux;
        opacity: 0.1; transform: translateX(-50%);
        pointer-events: none;
        @include mobile {font-size: 200px; line-height: 218px;}
    }
    &::after {
        content: "”"; position: absolute; left: 50%; top: 100%;
        font-family: 'Elgoc'; font-size: 280px; color: $bordeaux;
        opacity: 0.1; transform: translate(-50%, -80px);
        pointer-events: none;
        @include mobile {font-size: 200px;}
    }
    // @include tablet {padding: 65px 0 40px;}
     @include mobile {font-size: 34px;}
}