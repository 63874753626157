.page-template-page-contact .wrapper {
    overflow: hidden;
}
.contact {
    width: 960px;
    max-width: 100%;
    margin: 0 auto;
    padding: 100px 15px 75px;
    position: relative;
}
.contactBlock {
    width: 100%;
    padding: 60px;
    position: relative;
    z-index: 2;
    background-color: $white;
    box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.07);
}
.contact__subtitle {
    font-size: 26px;
    text-align: center;
}
.contact .hero__title {
    text-align: center;
    margin-bottom: 30px;
}
.contact__form {
    margin-top: 40px;
    font-size: 26px;
    font-family: 'Elgoc', sans-serif;
}

.contact .input,
.contact .textarea {
    width: 100%;
    max-width: 100%;
    padding: 5px 15px;
    display: inline-block;
    background-color: $greyPale;
    border-bottom: 1px solid rgba(20, 2, 7, 0.1);
}
.contact .textarea {
    min-height: 140px;
}
.contact .input__container + .input__container {
    margin-top: 60px;
}
.contact .gform_footer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0;
    margin-top: 30px;
    text-align: center;
}
.contact .form__submit {
    margin: 0;
    font-size: 18px; font-weight: 400;
    border-radius: 4px;
}

.contact .loader.is-hidden {
    display: none;
}
.contact .loader svg {
    vertical-align: top;
}
.gform__recaptchaBranding {
    font-size: 12px; font-family: 'Calibre', "Arial", sans-serif;
    margin-top: 30px;
}
.grecaptcha-badge {
    visibility: hidden;
    position: fixed;
    bottom: 14px;
    right: -186px;
}

.contactImage {
    position: absolute;
    z-index: 1;
}
.contactImage--1 {
    top: 0;
    left: -290px;
}
.contactImage--2 {
    top: 290px;
    right: -300px;
}
.contactImage--3 {
    bottom: 0;
    left: -110px;
}


@include tablet {
    .contactBlock {
        padding: 20px;
    }
    .contact .input__container + .input__container {
        margin-top: 20px;
    }
}