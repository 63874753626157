.homepageFeed {
    padding: 35px 0; overflow: hidden;
}
.homepageFeed__row {
    // flex-grow: 1;
    & + .feedItem {margin-top: 40px;}
   .feedItem + & {margin-top: 40px;}
}
.homepageFeed__col {
    display: flex; flex-direction: column;
}
.homepageFeed__subcol {
    display: flex; flex-direction: column;
    &--small {align-self: flex-start;}
}

.homepageFeed__showMore{
	@extend .btn__showMore;
	display: inline-flex;
}
@include tablet-sm {
	.homepageFeed__col--2{display: none;}
}