.mediaSection {
    padding: 35px 0;
}

.mediaSection__sidebar-image {
    position: relative; z-index: 1;
    width: calc(75vw - 35px);
    transform: translateX(-45px);
}
.mediaSection__sidebar-video {
    position: relative; z-index: 1;
    width: calc(75vw - 35px); padding-bottom: 41vw;
    & > video,  & > iframe {
        position: absolute; left: 0; top: 0;
        width: 100%; height: 100%;
    }
}
.mediaSection__sidebar-source {
    width: calc(75vw - 80px); margin-top: 30px;
    font-size: 16px; line-height: 24px;
    p {color: $grey; display: inline;}
}
.mediaSection__sidebar-source + .mediaSection__sidebar-source {
    margin-top: 0;
}

.mediaSection__container {

}
.mediaSection__content-title {
    display: block; margin-bottom: 30px;
    font-size: 24px; line-height: 32px; font-weight: 500;
}
.mediaSection__content-image {
    max-width: 100%;
}
.mediaSection__content-video {
    position: relative;
    width: 100%; padding-bottom: 56%;
    & > video,  & > iframe {
        position: absolute; left: 0; top: 0;
        width: 100%; height: 100%;
    }
}
.mediaSection__content-source {
    display: block; margin-top: 10px;
    font-size: 16px; line-height: 24px; color: $grey;
    p {
        display: inline;
        &::after {
            content: "\a";
            white-space: pre;
        }
    }
    .mediaSection__content-source-label {
        display: inline;
        &::after {content: none;}
    }
}
.mediaSection__content-source + .mediaSection__content-source {
    margin-top: 0; margin-bottom: 10px;
}

@media (max-width : $mq-tablet) {
    .mediaSection__sidebar-image {width: calc(85vw - 35px);}
    .mediaSection__sidebar-source {width: calc(85vw - 80px);}
}
@media (max-width : $mq-mobile) {
    .mediaSection__sidebar-image {width: calc(95vw - 35px);}
    .mediaSection__sidebar-source {width: calc(95vw - 80px);}
}
@media (max-width : $mq-mobile-sm) {
    .mediaSection__sidebar-image {width: calc(100vw - 35px);}
    .mediaSection__sidebar-source {width: calc(100vw - 80px);}
}