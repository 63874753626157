.lastStudies {
    padding: 35px 0;
    overflow: hidden;
}
.lastStudies__title {
    display: block; margin-bottom: 40px;
    font-size: 34px; font-family: 'Elgoc';
    @include tablet-sm {margin-bottom: 20px;}
    @include mobile {margin-bottom: 0;}

}
.lastStudies__container {

}
.lastStudies__slider {
    .slick-list {
        padding: 0 45px;
        @include mobile {padding: 0 18px;}
    }
    .slick-arrow {
        position: absolute; left: 27px; top: 50%; z-index: 1;
        display: flex; justify-content: center; align-items: center;
        width: 60px; height: 60px; margin-top: -30px; padding: 0;
        text-align: center;
        border-radius: 100%;
        background: #fff; border: none;
        // opacity: 0; 
        box-shadow: 0 4px 12px rgba(26,23,19,0.35);
        @include mobile {width: 50px; height: 50px;}
    }
    .slick-next {
        left: auto; right: 27px;
    }
    .slick-disabled{display: none!important;}
}
.lastStudies__study-container {
    padding-top: 20px; padding-bottom: 20px;
}
.lastStudies__study {
    position: relative;
    display: block; margin-bottom: 20px;
    cursor: pointer;
    &::before {
        content: "";
        position: absolute; top: 0; left: 0; z-index: -1;
        display: block; width: 100%; height: 100%;
        background-color: rgba(26, 23, 19, 0.35);
        box-shadow: 0 4px 12px rgba(26, 23, 19, 0.35);
        transition: 0.5s;
    }
    &:hover:before {
        box-shadow: 0 4px 15px 5px rgba(26, 23, 19, 0.3);
    }
    .studyCover__container {
        transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.84);
        backface-visibility: hidden;
        &:hover {
            transform: scale(1.05);
        }
    }
}
.lastStudies__study-image {
    width: 100%;
}