.introduction {
    padding: 35px 0;
}
.introduction__container {

}
.introduction__title {
    padding: 20px 0 60px;
    font-size: 22px; font-weight: 600; color: $bordeaux; text-align: center;
}
.introduction__content {
    text-align: justify; font-size: 22px; line-height: 30px;;
}
