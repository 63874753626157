.textImageCol {
    padding: 35px 0;
}
.textImageCol__container {

}
.textImageCol__text {
    font-size: 22px; line-height: 30px; text-align: justify;
}
.textImageCol__image {

}
.textImageCol__image-title {
    display: block; margin-bottom: 5px;
    font-size: 22px; line-height: 30px; font-weight: 500;
}
.textImageCol__image-content {
    width: 100%;
    margin-bottom: 10px;
}
.textImageCol__image-source {
    font-size: 16px; line-height: 24px; color: $grey;
}
.textImageCol__image-source + .textImageCol__image-source {
    margin-bottom: 10px;
}
.textImageCol__note {
    margin-top: 35px;
    font-size: 16px; line-height: 24px;
    p {color: $grey}
    @include mobile {padding-left: 30%;}
}