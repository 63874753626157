.author {
    padding: 10px 0 35px;
}
.author__container {
    width: 50%;
    line-height: 24px;
    @include mobile {width: 100%;}
}
.author__container + .author__container {
    margin-top: 30px;
}
.author__name {
    font-weight: 600;
}
.author__job {
    margin-bottom: 15px;
}
.author__about {
    color: $grey;
}