.textCol {
    padding: 35px 0;
}
.textCol__container {

}
.textCol__content {
    font-size: 22px; line-height: 30px; text-align: justify;
}
.textCol__note {
    margin-top: 35px;
    font-size: 16px; line-height: 24px;
    p {color: $grey}
    @include mobile {padding-left: 30%;}
}

@media (max-width : $mq-mobile) {
	.textCol__content {font-size: 20px; line-height: 24px;}
}