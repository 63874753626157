/* EVENT LIST */
.inTheMediaList {
    padding: 35px 0;
    @include tablet-sm {padding: 15px 0;}
}
.inTheMediaList__more {
    display: flex; justify-content: center; align-items: center;
    padding-top: 35px;
}
.inTheMediaList__more-button {
	@extend .btn__showMore;
}

// .inTheMediaListHome__mobileSliderContent{box-shadow: 0 10px 60px rgba(0,0,0,0.05);}
.inTheMediaListHome__mobileSlider{display: flex; margin: 0 -12.5px;}
.inTheMediaListHome__mobileSlider .inTheMediaList__cell{padding: 0 12.5px;}
.inTheMediaListHome__mobileSlider{
	.slick-track { 	display: flex !important; }  
	.slick-slide { 	
		height: inherit !important;
	 	display: flex !important; 	
	 	justify-content: center; 	
	 	align-items: center; 
	}
	.slick-slide>div{height: 100%;}
	.inTheMediaList__cell{height: 100%;}
}
@include medium {
	.inTheMediaListHome__mobileSliderContent{margin-right: -45px; overflow: hidden;}
	.inTheMediaListHome__mobileSlider{display: block; width: 80%; margin: 0 -12.5px;}
	.inTheMediaListHome__mobileSlider .slick-list{overflow: visible;}
	.inTheMediaListHome__mobileSlider .inTheMediaList__cell{padding: 0 12.5px;}
}
@include tablet {
	.inTheMediaListHome__mobileSliderContent{margin-right: -30px;}
}
@include mobile {
	.inTheMediaListHome__mobileSliderContent{margin-right: -18px;}
	.inTheMediaListHome__mobileSlider .inTheMediaCard__title{font-size: 24px; line-height: 28px;}
}