.linksList {
    padding: 60px 0;
    @include tablet-sm {
        padding: 30px 0;
    }
}
.linksList__wrapper {
    display: flex;
    @include medium {flex-wrap: wrap;}
}

.linksList__title {
    width: 36%;
    // font-size: 84px; line-height: 90px; color: $bordeaux; font-family: 'Elgoc';
    @include medium {width: 100%;}
    // @include tablet-sm {font-size : 64px; line-height: 70px;}
    // @include mobile {font-size : 44px; line-height: 50px;}
}
.linksList__content {
    display: flex; justify-content: flex-end;
    width: 64%; padding: 35px 0 0 90px;
    @include medium {width: 100%; justify-content: flex-start; flex-wrap: wrap;}
    @include tablet {padding: 25px 0 0 40px;}
    @include mobile {padding: 20px 0 0;}
}
.linksList__item {
    display: flex; align-items: flex-start;
    min-width: 50%; margin-bottom: 25px;
    @include tablet {width: auto;}
    @include mobile {width: 100%;}
}
.linksList__item-image {
    width: 120px;
    transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.84); backface-visibility: hidden;
    img {vertical-align: top; box-shadow: 0 22px 44px rgba(26, 23, 19, 0.4);}
    &:hover {
        transform: scale(1.05);
    }
}
.linksList__item-infos {
    padding: 20px 40px 0;
}
.linksList__item-surtitle {
    font-size: 16px; color: $bordeaux; font-weight: 500; text-transform: uppercase;
}
.linksList__item-title {
    display: block; width: 200px; max-width: 100%;
    font-family: 'Elgoc'; font-size: 24px; line-height: 30px;
}