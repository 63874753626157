.activityReports {
    padding: 60px 0;
    overflow: hidden;
    @include tablet-sm {
        padding: 30px 0;
    }
}
.activityReports__title {
    display: block; margin-bottom: 40px;
    // font-size: 84px; font-weight: 400; font-family: 'Elgoc'; color: $bordeaux;
    @include tablet {
        max-width: 100%; margin: 0;
        // font-size : 74px; line-height: 80px;
    }
    // @include mobile {font-size : 64px; line-height: 70px;}
}
.activityReports__container {

}
.activityReports__slider {
    .slick-list {
        padding: 0 45px;
        @include mobile {padding: 0 18px;}
    }
    .slick-arrow {
        position: absolute; left: 27px; top: 50%; z-index: 1;
        display: flex; justify-content: center; align-items: center;
        width: 60px; height: 60px; margin-top: -30px; padding: 0;
        text-align: center;
        border-radius: 100%;
        background: #fff; border: none;
        // opacity: 0; 
        box-shadow: 0 4px 12px rgba(26,23,19,0.35);
        @include mobile {width: 50px; height: 50px;}
    }
    .slick-next {
        left: auto; right: 27px;
    }
    .slick-disabled{display: none!important;}
}
.activityReports__study-container {
    padding-top: 20px; padding-bottom: 20px;
}
.activityReports__study {
    position: relative;
    display: block; margin-bottom: 20px;
    cursor: pointer;
    &::before {
        content: "";
        position: absolute; top: 0; left: 0; z-index: -1;
        display: block; width: 100%; height: 100%;
        background-color: rgba(26, 23, 19, 0.35);
        box-shadow: 0 4px 12px rgba(26, 23, 19, 0.35);
        transition: 0.5s;
    }
    &:hover:before {
        box-shadow: 0 4px 15px 5px rgba(26, 23, 19, 0.3);
    }
    .studyCover__container {
        transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.84);
        backface-visibility: hidden;
        &:hover {
            transform: scale(1.05);
        }
    }
}
.activityReports__study-image {
    width: 100%;
}