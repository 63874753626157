.mediaHighlights {
    padding: 35px 0;
}
.mediaHighlights__container {
    display: flex; justify-content: center;
    background-color: $grey; background-size: cover; background-position: center;
}
.mediaHighlights__content {
    display: flex; flex-direction: column;
    min-width: 50%; width: 850px; max-width: 100%; padding: 60px 0;
    color: $white; text-align: center;
}

.mediaHighlights__type {

}
.mediaHighlights__text {
    display: flex; justify-content: center; align-items: center;
    min-height: 430px; padding: 20px 0 60px;
    font-size: 84px; line-height: 90px; font-family: 'Elgoc';
}
.mediaHighlights__link {
    text-decoration: underline;
}

@media (max-width : $mq-tablet) {
    .mediaHighlights__text {font-size: 74px; line-height: 78px;}
}
@media (max-width : $mq-tablet-sm) {
    .mediaHighlights__text {font-size: 54px; line-height: 58px;}
}
@media (max-width : $mq-mobile) {
    .mediaHighlights__text {font-size: 40px; line-height: 48px;}
}
@media (max-width : $mq-mobile-sm) {
}