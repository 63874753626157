/* EVENT CARD */
$EC_padLeft: 45px;
$EC_padRight: 35px;
.eventCard {
    position: relative;
    display: inline-flex; flex-direction: column;
    width: 100%; max-width: 500px;
    background-color: $white;
    color: $greyDark;
    & > * {
        pointer-events: none;
    }
    &::before {
        content: ''; position: absolute; z-index: -1; left: 0; right: 0; top: 0; bottom: 0;
        box-shadow: 0 10px 60px rgba(0, 0, 0, 0.05);
    }
}
.eventCard__heading {
    min-height: 173px; padding: 52px $EC_padRight 0 $EC_padLeft;
}
.eventCard__date {
    display: block; min-height: 50px;
    font-size: 46px; line-height: 50px;
    word-wrap: break-word;
}
.eventCard__title {
    height: 28px;
    font-family: 'Elgoc'; font-size: 30px; line-height: 30px;
    span {
        position: relative; z-index: 2;
        background-color: $white; box-shadow: 5px 0 0 1px $white, -2px 0 0 1px $white;
    }
}
.eventCard__body {
    width: 100%; padding: 0 0 0 $EC_padLeft;
}
.eventCard__cover {
    position: relative;
    width: 100%; height: 240px;
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.84);
    overflow: hidden; backface-visibility: hidden; -webkit-mask-image: -webkit-radial-gradient(white, black);
    transform-origin: right;
}
.eventCard__cover-image {
    display: block; width: 100%; height: 100%;
    background-position: center; background-size: cover; background-repeat: no-repeat;
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.84);
    backface-visibility: hidden;
}
.eventCard__footer {
    display: flex; justify-content: space-between; flex-wrap: wrap;
    width: 100%; height: 100px; padding: 30px $EC_padRight 30px $EC_padLeft;
    text-transform: uppercase; font-size: 16px;
    span {
        padding-right: 15px; padding-bottom: 5px;
        &:last-child {
            padding-right: 0;
        }
    }
    
}
.eventCard__infos {
    color: $bordeaux; font-weight: 500;
}

@media (max-width: 1520px) {
    .eventCard__heading {min-height: 155px; padding-top: 42px;}
    .eventCard__date {font-size: 42px; min-height: 46px;}
}

$EC_padSide-bp1: 20px;
@media (max-width: 1360px) {
    .eventCard__heading {min-height: 130px; padding-top: $EC_padSide-bp1; padding-right: $EC_padSide-bp1; padding-left: $EC_padSide-bp1;}
    .eventCard__date {font-size: 34px; min-height: 38px;}
    .eventCard__body {padding-left: $EC_padSide-bp1;}
    .eventCard__footer {padding-right: $EC_padSide-bp1; padding-left: $EC_padSide-bp1;}
}