.team {
    padding: 60px 0;
    position: relative;
    @include tablet-sm {
        padding: 30px 0;
    }
}
.team__wrapper {
    padding-right: calc(25vw + 65px);
    @include tablet {
        padding: 0;
    }
}
.team__illustration {
    position: absolute; right: 0; top: 0;
    width: 25vw; height: 100%;
    background-size: cover; background-position: center;
    @include tablet {
        display: none;
    }
}

.team__title {
    max-width: 500px; margin-bottom: 32px;
    // font-size: 84px; line-height: 90px; color: $bordeaux; font-family: 'Elgoc';
    @include tablet {
        max-width: 100%; margin: 0;
        // font-size : 74px; line-height: 80px;
    }
    // @include mobile {font-size : 46px; line-height: 46px;}
}
.team__content {
    display: flex;
    @include tablet {
        flex-direction: column;
    }
}

.team__menu {
    width: 50%; padding-right: 30px;
    @include tablet {
        display: flex; flex-wrap: wrap;
        width: 100%;
    }
}
.team__menu-item {
    margin-top: 30px; padding-left: 16px; padding-bottom: 2px;
    border-left: 1px solid transparent;
    &:hover {border-color: $greyLight;}
    &.is-active {border-color: $bordeaux;}
    cursor: pointer;
    @include tablet {
        width: 220px; margin-top: 15px;
    }
}
.team__menu-name {
    font-size: 24px; line-height: 32px; font-weight: 500;
    @include tablet {
        font-size: 20px; line-height: 28px; 
    }
}
.team__menu-role {
    color: $grey;
}

.team__sheet {
    width: 50%;
    @include tablet {
        width: 100%; margin-top: 40px;
    }
}
.team__sheet-item {
    display: none;
    &.is-active {display: block;}
}
.team__sheet-name {
    max-width: 400px;
    font-size: 66px; line-height: 66px; font-family: 'Elgoc';
    @include tablet {
        max-width: 100%;
        font-size : 56px; line-height: 56px;
    }
    @include mobile {font-size : 38px; line-height: 38px;}
}
.team__sheet-role {
    margin-top: 32px; color: $grey;
    @include tablet {
        margin-top: 10px;
    }
}
.team__sheet-biography {
    margin-top: 64px;
    text-align: justify; line-height: 24px;
    @include tablet {
        margin-top: 20px;
    }
}
