.comments {
    padding: 35px 0;
}
.comments__title {
    display: block; margin-bottom: 40px;
    font-size: 34px; font-family: 'Elgoc';
}
.comments__button {
    margin-bottom: 40px;
}
.comments__container {
    max-width: 1050px; width: 100%;
}
.comments__item {
    margin-top: 50px;
    &:first-child {margin-top: 0;}
}
.comments__item-heading {
    display: flex; align-items: flex-end;
    margin-bottom: 8px;
}
.comments__item-title {
    font-size: 24px; line-height: 24px; font-family: 'Elgoc';
}
.comments__item-date {
    margin-left: 40px;
    font-size: 16px; color: $grey;
}
.comments__item-text {
    font-size: 22px; line-height: 30px;
}