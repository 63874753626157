.scrollNav{
    z-index: 10; position: relative;
    margin-top: -121px;
    border: 1px solid #dadad9; border-right: none; border-left: none;
    &.fixed{
        position: fixed; top: 0; right: 0; left: 0;
        margin-top: 0;
        background: #fff;  border-top: 1px solid #d0cccd; border-bottom: 1px solid #aba8a8;
    }
}
.scrollNav__content{display: flex; justify-content: space-around; align-items: center; overflow: auto;}
.scrollNav__link{
    display: flex; justify-content: center; align-items: center; flex-grow: 1;
    height: 90px; padding: 0 15px;
    white-space: nowrap;
    font-size: 18px; font-weight: 500; letter-spacing: -0.31px; color: #fff;
    border-bottom: 4px solid transparent; border-top: 4px solid transparent;
    &:focus,
    &:hover,
    &.active{border-bottom: 4px solid #fff;}
    .fixed &{
        color: #000;
        
    }
    .fixed &:focus,
    .fixed &:hover,
    .fixed &.active{border-bottom: 4px solid $bordeaux;}
}


@include medium {
    .scrollNav__content{justify-content: flex-start;}
}
@include tablet {
    .scrollNav{margin-top: -92px;}
}