.chapterSubtitle {
    padding: 80px 0 65px;
    &--hasImage {
        padding: 80px 0 35px;
    }
    @include mobile {padding: 30px 0 25px;}
}
.chapterSubtitle__inner {
    @include tablet {
        .inner__sidebar {order: 1;}
        .inner__content {order: 2;}
    }
}
.chapterSubtitle__image {
    position: relative; z-index: 1;
    width: 41.7vw; max-width: 100vw;
    transform: translateX(-45px);
    @include tablet-sm {
        width: calc(100vw - 17px);
        transform: translateX(-18px);
    }
}
.chapterSubtitle__image-source {
    margin-top: 30px;
    font-size: 16px; line-height: 24px;
    p {color: $grey; display: inline;}
    @include mobile {padding-left: 30%;}
}
.chapterSubtitle__image-source + .chapterSubtitle__image-source {
    margin-top: 0;
}
.chapterSubtitle__container {
    .chapterSubtitle--hasImage & {
        padding-top: 110px;
        @include mobile {padding-top: 60px;}
    }
}
.chapterSubtitle__heading-col {
    &#{&} {@include mobile {padding: 0;}}
}
.chapterSubtitle__heading {
    display: flex; justify-content: flex-end;
    text-align: right;
}
.chapterSubtitle__heading-number {
    position: relative;
    width: 100%; padding-top: 5px;
    font-size: 72px; font-family: 'Elgoc';
    &::before {
        content: ""; position: absolute; right: 0; top: 0;
        width: 100%; height: 0;
        border-top: 1px solid $bordeaux;
    }
    .chapterSubtitle--hasImage & {
        padding-top: 40px;
        @include mobile {padding-top: 10px;}
    }
}
.chapterSubtitle__content {
    .chapterSubtitle--hasImage & {
        padding-top: 40px;
        @include mobile {padding-top: 10px;}
    }
}
.chapterSubtitle__subtitle {
    min-height: 72px;
    font-size: 24px; font-weight: 500; line-height: 36px;
    @include mobile {font-size: 20px; line-height: 26px;}
}
.chapterSubtitle__chapo {
    display: none;
    font-size: 22px; line-height: 30px; text-align: justify;
    .chapterSubtitle--hasImage & {
        display: block;
    }
}