.thematicHighlights {
    padding: 0;
    background-color: $greyDark;
    background-image: linear-gradient(-13deg, rgba(255, 57, 59, 0) 0%, 
    rgba(253, 56, 58, 0.01) 31%, #87122e 100%), url('../../images/thematic-highlights-bg.jpg');
    background-size: cover; background-position: center;
}
.thematicHighlights__slider {
    position: relative;

    .slick-slide {cursor: pointer;}
    .slick-slide.slick-current {cursor: grab;}
}
.thematicHighlights__item-container {
    
}
.thematicHighlights__item {
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    // bug on IE 
    // min-height: calc(15vw + 350px); 
    height: calc(15vw + 350px); 
    padding: 0 0 60px;
    color: $white;
    
    @include tablet {
        height: auto;
        // potentiellement bug IE, mais on va supposer que ya pas du IE 11 sur tablette/mobile
        min-height: calc(35vw + 135px);
    }
    @include mobile {
        // potentiellement bug IE, mais on va supposer que ya pas du IE 11 sur tablette/mobile
        min-height: 300px; 
        padding-bottom: 40px;
    }
}
.thematicHighlights__item-content {
    position: relative;
    display: flex; align-items: center; justify-content: center; flex-grow: 1; flex-shrink: 1;
    width: 100%;  min-height: 1px;
    &::before {
        content: ""; position: absolute; left: 0; top: 47%;
        width: 100px; height: 0;
        border-bottom: 1px solid $white;
        opacity: 0.7; transform: translateX(-50%);
        @include tablet-sm {width: 50px;}
        @include mobile {top: 48%;}
    }
}
.thematicHighlights__item-title {
    padding: 30px 60px;
    font-family: 'Elgoc'; font-size: 84px; line-height: 76px; text-align: center;
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.84);
    @include tablet-sm {font-size: 60px; line-height: 64px;}
    @include mobile {font-size: 46px; line-height: 50px;}
}
.thematicHighlights__item-quantity {
    position: absolute;
    padding: 30px 0 0 5px;
    font-size: 18px; font-family: 'Calibre';
    @include mobile {padding: 15px 0 0 5px; font-size: 14px;}
}
.thematicHighlights__item-link {
    color: $white; text-decoration: underline;
    opacity: 0; transition: opacity 0.4s;
    .slick-active & {
        opacity: 1;
        transition: opacity 0.4s 0.8s;
    }
}