.tabs {
    padding: 35px 0;
    .section {padding: 0;}
    .section__title {display: none;}
}
.tabs__container {

}
.tabs__header {
    margin-bottom: 30px;
    overflow-x: auto;
    @include scrollbar;
}
.tabs__header-list {
    display: flex;
    width: max-content;
}
.tabs__title {
    flex-shrink: 0;
    display: inline-block; padding-bottom: 10px; margin-left: 30px; margin-right: 18px;
    font-family: 'Elgoc'; font-size: 34px; transition: 0.3s;
    opacity: 0.2; cursor: pointer;
    &.is-active {opacity: 1;}
    &:first-child {margin-left: 0;}
    @include mobile {
        margin-left: 10px;
        font-size: 26px; line-height: 26px;
    }
}

.tabs__body {
    
}
.tabs__content {
    height: 0; width: 100%;
    overflow: hidden;
    &.is-active {height: auto;}
}
