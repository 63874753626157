/***
** FONT WEIGHT
** - 100 Thin (Hairline)
** - 200 ExtraLight (UltraLight)
** - 300 Light
** - xxx Book (sometimes heavier than Regular)
** - 400 Regular / Normal
** - 500 Medium
** - 600 SemiBold (DemiBold)
** - 700 Bold
** - 800 ExtraBold (UltraBold)
** - 900 Black (Heavy)
***/

// @mixin load-font($font-name, $font-file, $font-weight: 400, $font-style: normal){
//   $svg-id: replace("${font-file}", "-", "", "g");

//   font-family: '#{$font-name}';
//   src: url('../fonts/#{$font-file}.eot');
//   src: url('../fonts/#{$font-file}.eot?#iefix') format('embedded-opentype'),
//   url('../fonts/#{$font-file}.woff2') format('woff2'),
//   url('../fonts/#{$font-file}.woff') format('woff'),
//   url('../fonts/#{$font-file}.ttf') format('truetype'),
//   url('../fonts/#{$font-file}.svg##{$svg-id}') format('svg');
//   font-weight: $font-weight;
//   font-style: $font-style;
// }

// @font-face {@include load-font('roboto', 'roboto-light' , 300);}
// @font-face {@include load-font('roboto', 'roboto-regular');}
// @font-face {@include load-font('roboto', 'roboto-medium', 500);}
// @font-face {@include load-font('roboto', 'roboto-bold'  , 700);}

// @font-face {@include load-font('ubuntu', 'ubuntu-light' , 300);}
// @font-face {@include load-font('ubuntu', 'ubuntu-regular');}
// @font-face {@include load-font('ubuntu', 'ubuntu-medium', 500);}
// @font-face {@include load-font('ubuntu', 'ubuntu-bold'  , 700);}

@font-face {
    font-family: 'Calibre';
    src: url('../../fonts/Calibre-Medium.woff2') format('woff2'),
        url('../../fonts/Calibre-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('../../fonts/Calibre-Regular.woff2') format('woff2'),
        url('../../fonts/Calibre-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Elgoc';
    src: url('../../fonts/Elgoc-Regular.woff2') format('woff2'),
        url('../../fonts/Elgoc-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.baseTypo{font-family: 'Calibre', sans-serif;}
.titleTypo{font-family: 'Elgoc', serif;}