/* EVENT CARD */
.inTheMediaCard {
    position: relative;
    display: inline-flex; flex-direction: column;
    width: 100%; max-width: 500px; min-height: 410px; height: 100%;
    background-color: $white;
    color: $greyDark;
    &--noImage {
        min-height: 360px; padding-top: 45px;
    }
    & > * {
        pointer-events: none;
    }
    &::before {
        content: ''; position: absolute; z-index: -1; left: 0; right: 0; top: 50%; height: 80%;
        box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07); transform: translateY(-50%);
    }
}
.inTheMediaCard__heading {
    display: flex; align-items: center;
    min-height: 165px; padding: 35px 35px 20px 45px;
    @include tablet-sm {padding-top: 15px; padding-bottom: 15px;}
}
.inTheMediaCard__image {
    display: block; max-width: 100%; max-height: 90px; max-width: 60%;
    vertical-align: top;
    // backface-visibility: hidden; -webkit-mask-image: -webkit-radial-gradient(white, black);
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.84);
    .inTheMediaCard:hover & {transform: scale(1.12);} 
}

.inTheMediaCard__body {
    flex-grow: 1;
    width: 100%; padding: 0 35px 0 45px;
}
.inTheMediaCard__title {
    display: block;
    font-size: 30px; line-height: 1; font-family: 'Elgoc';
}
.inTheMediaCard__author {
    display: inline-block; margin-top: 5px;
    font-size: 16px; line-height: 24px; color: $grey;
}

.inTheMediaCard__footer {
    // display: flex; justify-content: space-between; flex-wrap: wrap;
    width: 100%; padding: 30px 35px 40px 45px;
    text-transform: uppercase; font-size: 16px;
    span {
        display: block; width: 100%;
        padding-right: 15px; padding-bottom: 5px;
        &:last-child {
            padding-right: 0;
        }
    }
    
}
.inTheMediaCard__label {
    color: $bordeaux; font-weight: 500;
}

@media (max-width: 1500px){
    .inTheMediaCard__title {font-size: 26px;}
}