.textCloud {
    padding: 60px 0;
    overflow: hidden;
    @include tablet-sm {
        padding: 30px 0;
    }
}

.textCloud__title {
    // font-family: 'Elgoc'; font-size: 84px; line-height: 90px; color: $bordeaux;
    @include tablet {
        max-width: 100%; margin: 0;
        // font-size : 74px; line-height: 80px;
    }
    // @include mobile {font-size : 64px; line-height: 70px;}
}
.textCloud__subtitle {
    margin-top: 10px;
    font-size: 24px; line-height: 36px; color: $bordeaux; font-weight: 500;
    @include mobile {font-size : 20px; line-height: 32px;}
}

.textCloud__block {
    position: relative;
    display: flex; flex-wrap: wrap;
    width: 100%; padding: 0 0 50px;
    border-bottom: 1px solid $greyLight;
}
.textCloud__block-title {
    flex-grow: 1;
    display: inline-block; padding-right: 20px; margin-bottom: 5px;
    font-size: 24px; font-weight: 500;
}
.textCloud__block-subtitle {
    display: inline-block;
    color: $grey;
}
.textCloud__block-text {
    width: 100%; margin-top: 25px;
    font-size: 20px; line-height: 24px; text-align: justify;
    // display: -webkit-box;
    // -webkit-line-clamp: 5;
    // -webkit-box-orient: vertical;  
    max-height: 118px;
    overflow: hidden;
    .textCloud__block.is-active & {
        // display: block;
        max-height: none;
    }
}
.textCloud__readmore {
    position: absolute; left: 0; bottom: 5px;
    color: $grey;
    cursor: pointer;
    .more {display: inline;}
    .less {display: none;}
    .textCloud__block.is-active & {
        .more {display: none;}
        .less {display: inline;}
    }
    &.is-hidden {display: none;}
    &:hover,
    &:focus{text-decoration: underline;}
}

// SIMPLE DISPOSITION
.textCloud-simple {

    &__heading {
        width: 520px; max-width: 100%; margin-bottom: 50px;
    }
    &__content {
        padding-left: 135px; overflow: hidden;
        @include tablet {padding: 0;}
    }
    &__row {
        display: flex; justify-content: space-between; align-items: flex-start; flex-wrap: wrap;
        margin: 0 -34px;
        &--large {
            margin: 0 -70px;
            @include tablet {margin: 0 -34px;}
        }
    }
    &__col {
        width: 33.333%; padding: 0 34px 50px;
        &--large {
            width: 50%; padding: 0 70px 50px;
            @include tablet {padding: 0 34px 50px;}
        }
        @include tablet {width: 50%;}
        @include mobile {width: 100%;}
    }
}

// RIGHT TITLE DISPOSITION
.textCloud-right {

    &__heading {
        width: calc((100% - 135px) * 33 / 100 + 135px); max-width: 100%; margin-bottom: 50px; padding: 0 34px;
        @include tablet {width: 50%;}
        @include tablet-sm {width: 100%;}
    }
    &__content {
        padding-left: 135px;
        @include tablet {padding: 0;}
    }
    &__row {
        display: flex; justify-content: space-between; align-items: flex-start; flex-wrap: wrap;
        margin: 0 -34px;
        &--large {margin: 0 -34px; align-items: flex-end;}
    }
    &__col {
        width: 33.333%; padding: 0 34px 50px;
        &--large {flex-grow: 1;}
        @include tablet {width: 50%;}
        @include mobile {width: 100%;}
    }
}

// RED BG DISPOSITION
.textCloud-redbg {
    padding: 100px 0 140px;
    background-color: $bordeaux;
    @include tablet-sm {padding-top: 0;}

    .textCloud__title {color: $white;}
    .textCloud__subtitle {color: $white;}

    &__wrapper {
        display: flex; justify-content: space-between;
        @include tablet {flex-wrap: wrap;}
    }
    &__heading {
        flex-shrink: 0;
        width: 400px; max-width: 100%; padding: 60px 40px 0 0;
        @include tablet {width: 100%; margin-bottom: 50px;}
    }
    &__content {

    }
    &__row {
        display: flex; flex-direction: row-reverse; flex-wrap: wrap;
        margin: 0 -12px;
    }
    &__col {
        position: relative;
        width: 50%; padding: 0 12px 24px;
        &:nth-child(even) {
            transform: translateY(90px);
            @include tablet-sm {transform: none;}
        }
        @include tablet-sm {width: 100%;}
    }

    .textCloud__block {
        flex-direction: column; align-items: flex-start;
        height: 100%; padding: 40px 70px; 
        background-color: $white; box-shadow: 0 12px 24px -10px rgba(20, 2, 7, 0.2);
        text-align: center;
    }
    &__col:last-child .textCloud__block {
        position: absolute; z-index: 1;
        width: calc(100% - 24px); height: auto;
    }
    .textCloud__block-title {width: 100%; padding: 0; flex-grow: 0;}
    .textCloud__block-subtitle {width: 100%;}
    .textCloud__block-text {}
    .textCloud__readmore {
        position: static; margin-top: 15px;
        &:hover,
        &:focus{text-decoration: underline;}
    }
}

.textCloud-redbg__row.is-hidden {
    padding-left: 400px; margin-bottom: -85px;
    @include tablet-sm {padding: 0;}
    .textCloud__block {
        flex-direction: column;
        position: static; width: 100%; padding: 0 70px;
        opacity: 0; pointer-events: none;
    }
}

// IMAGE DISPOSITION
.textCloud-image {
    display: flex;
    @include tablet {flex-wrap: wrap;}

    &__heading {
        width: 50%;
        padding-right: 100px;
        @include tablet {width: 100%; padding: 0;}
    }
    &__image {
        position: relative;
        width: calc(100% + 45px); margin-top: 90px; height: 100%;
        transform: translateX(-45px); background-color: $black;
        @include tablet {width: calc(100% + 90px); height: auto; margin: 40px -45px; transform: none;}
        img {
            display: block; width: 100%;
            vertical-align: top;
        }
        &-overlay {
            position: absolute; left: 0; top: 0;
            width: 100%; height: 100%;
            mix-blend-mode: screen;
            background-image: linear-gradient(45deg, rgba(255, 57, 59, 0) 0%, $bordeaux 100%);
        }
        &-cover {
            width: 100%; height: 100%; min-height: 500px;
            background-size: cover; background-position: center;
            @include tablet {min-height: 400px;}
            @include tablet-sm {min-height: 300px;}
            @include mobile {min-height: 200px;}
        }
    }
    &__content {
        width: 50%;
        @include tablet {width: 100%;}
    }
    &__row {
        display: flex; flex-direction: row-reverse; flex-wrap: wrap;
        margin: 0 -12px;
    }
    &__col {
        position: relative;
        width: 50%; padding: 0 12px 24px;
        &:nth-child(even) {transform: translateY(90px);}
    }

    .textCloud__subtitle {max-width: 400px;}
    .textCloud__block {
        position: relative;
        margin-bottom: 50px;
    }
}
