.chronicleListIntro {
    display: flex;
    flex-wrap: nowrap;
    gap: 60px;
    margin: 65px 0;
    padding: 45px;
    background: $white;
    box-shadow: 0 10px 60px 0 rgba($black, 0.07);

    @include tablet {
        gap: 50px;
        margin: 60px 0;
        padding: 40px;
    }
    @include tablet-sm {
        gap: 30px;
        padding: 30px;
        flex-wrap: wrap;
    }
}

.chronicleListIntro__image {
    flex: 0 0 130px;

    @include tablet-sm {
        flex: 1 1 100%;
        text-align: center;
    }
}

.chronicleListIntro__intro {
    font-family: 'Elgoc', serif;
    font-size: 36px;
    line-height: 36px;
    text-align: justify;
    color: $grey;

    @include tablet-sm {
        font-size: 30px;
        line-height: 30px;
    }
}

.chronicleListTitle {
    font-family: 'Elgoc', serif; font-size: 84px; color: $bordeaux;
    margin-bottom: 25px;
    @include tablet {font-size: 62px;}
    @include mobile {font-size: 40px;}
}


.chronicleCard {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    padding: 30px 45px;
    background: $white;
    box-shadow: 0 10px 60px 0 rgba($black, 0.07);
    transition: box-shadow .2s;

    &:hover {
        box-shadow: 0 10px 70px 0 rgba($black, 0.12);
    }
}
.chronicleCard + .chronicleCard {
    margin-top: 30px;
}
.chronicleCard__title {
    text-align: justify;
    font-family: 'Elgoc', serif;
    font-size: 26px;
    line-height: 26px;
}
.chronicleCard__date {
    color: $bordeaux;
    font-family: 'Calibre', "Arial", sans-serif;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
}

.chronicleList {
    padding-bottom: 60px;
}
.chronicleList__more {
    margin-top: 65px;
}
.chronicleList__more__button {
    margin: 0 auto;
}

.chronicleSingleNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}
.chronicleSingleNav__link {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    line-height: 16px;
    transition: color .2s;

    &:hover {
        color: $grey;
    }
}
.chronicleSingleNav__link--next {
    text-align: right;
}
.chronicleSingleNav__link__text {
    padding-top: 5px;
}