.searchResults {

}
.searchResults__list {
    display: flex; flex-wrap: wrap;

    @include mobile {
        [class*="col-"] {
            padding-bottom: 20px;
        }
    }
}
.searchResults__footer {
    display: flex; justify-content: center;
    margin-top: 90px;
}