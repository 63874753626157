.newsCard {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    padding: 30px 45px;
    background: $white;
    box-shadow: 0 10px 60px 0 rgba($black, 0.07);
    transition: box-shadow .2s;

    &:hover {
        box-shadow: 0 10px 70px 0 rgba($black, 0.12);
    }
}
.newsCard + .newsCard {
    margin-top: 30px;
}

.newsCard__title {
    text-align: justify;
    font-family: 'Elgoc', serif;
    font-size: 26px;
    line-height: 26px;
}
.newsCard__date {
    color: $bordeaux;
    font-family: 'Calibre', "Arial", sans-serif;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
}

.newsListTitle {
    font-family: 'Elgoc', serif; font-size: 84px; color: $bordeaux;
    margin-bottom: 25px;
    @include tablet {font-size: 62px;}
    @include mobile {font-size: 40px;}
}

.newsList {
    padding-bottom: 60px;
}

.newsList__more {
    margin-top: 65px;
}

.newsList__more__button {
    margin: 0 auto;
}