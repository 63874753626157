.summary-page-container {
    display: flex;
}
.summary-page-content {
    width: 100%;
}




.summary {
    width: 100%;
    height: 0;
    // @include tablet {display: none;}
}
.summary__inner {
    position: relative;
}
.summary__container {
    pointer-events: none;
    position: absolute; left: 0; top: 0; z-index: 1;
    // width: 2/12 * 100%;
    width: calc((100vw - 90px) / 6); padding: 60px 0;
    // transition: opacity 0.4s;

    > * {
      pointer-events: all;
    }

    @include tablet {
        position: fixed; top: 0; left: 0; z-index: 3;
        display: flex; align-items: center;
        width: 100%; height: 0; padding: 0;
        background-color: $white;
        transition: height 0.1s, opacity 0.1s;
    }
    .summary.is-fixed & {
        position: fixed; top: 100px; left: 45px;
        @include tablet {
            top: 0; left: 0;
            height: 60px;
            border-bottom: 1px solid $greyLight;
            transition: height 0.4s, opacity 0.1s;
        }
    }
    .summary.is-hidden & {
       opacity: 0;
       pointer-events: none;
       > * {
        pointer-events: none;
      }
    }
    .summary.has-study & {
        @include tablet {top: 78px;}
    }
}
.summary__select {
    display: none;
    max-width: calc(100% - 60px); padding:  0 2px 4px;
    background: none; border: none; border-bottom: 1px solid $greyDark;
    font-family: 'Elgoc';
    .icon {width: 11px; height: 11px; margin-left: 10px;}
    @include tablet {
        display: flex;
        margin-left: 30px;
        opacity: 0; transition: 0.05s;
    }
    @include mobile {
        max-width: calc(100% - 36px); margin-left: 18px;
    }
    .summary.is-fixed & {
        @include tablet {
            opacity: 1;
        }
    }
    .icon {flex-shrink: 0;}
}
.summary__select-text {
    display: inline-block;
    white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}

.summary__content {
    padding: 5px 0;
    background: $white;

    @include tablet {
        position: absolute; top: 100%; left: 0;
        max-height: 0; width: 100%; margin-top: 1px; padding: 0 30px;
        opacity: 0; transition: opacity 0.2s, max-height 0.3s, padding 0.4s;
        background-color: $white;
        overflow: hidden;
        &.is-open {
            max-height: 100vh; padding: 30px; border-bottom: 1px solid $greyLight;
        }
    }
    @include mobile {
        padding: 0 18px;
        &.is-open {
            padding: 18px;
        }
    }
    .summary.is-fixed & {
        @include tablet {
            opacity: 1;
        }
    }
}
.summary__list {
    max-height: calc(80vh - 300px); padding-right: 5px;
    overflow-y: auto;
    @include scrollbar;
    @include tablet {
        max-height: calc(100vh - 260px);
    }
}
.summary__list-item {
    padding-left: 16px; margin-bottom: 22px;
    border-left: 1px solid $greyLight;
    line-height: 24px; color: $grey;
    cursor: pointer;
    &.is-active {
        color: black; border-left-color: $bordeaux;
    }
    @include tablet {
        height: 24px; margin-bottom: 16px; padding-left: 14px;
    }
    &--sub {
        max-height: 0; margin: 0;
        overflow: hidden; transition: 0.2s;
        .summary.is-unwrap & {
            max-height: 100px; margin-bottom: 22px;
        }
    }
}
.summary__link {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    @include tablet {
        display: inline-block; width: 100%;
        overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
    }
}
.summary__link p {display: inline;}

.summary__more {
    display: inline-block; padding-top: 20px; padding-left: 16px;
    color: $bordeaux; text-decoration: underline;
    cursor: pointer;
    @include tablet {
        padding: 10px 0 0 14px;
    }
}
.summary__more-more {
    .summary.is-unwrap & {
        display: none;
    }
}
.summary__more-less {
    display: none;
    .summary.is-unwrap & {
        display: inline;
    }
}