.promotedLink {
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    padding: 60px 45px; margin: 60px 0;
    background-color: $greyDark;
    background-image: linear-gradient(0deg, rgba(255, 57, 59, 0) 0%, 
    rgba(253, 56, 58, 0.01) 31%, #87122e 100%), url('../../images/thematic-highlights-bg.jpg');
    background-size: cover; background-position: center;
    color: $white; text-align: center;
    @include tablet-sm {
        margin: 30px 0;
    }
}
.promotedLink__surtitle {
    width: 300px; max-width: 100%; margin-bottom: 50px;
    text-transform: uppercase; font-size: 16px;
}
.promotedLink__title {
    width: 650px; max-width: 100%;
    color: $white;
    // font-family: 'Elgoc'; font-size: 84px; line-height: 90px;
}
.promotedLink__description {
    width: 500px; max-width: 100%;  margin-top: 50px;
    line-height: 24px;
}
.promotedLink__cta {
    display: inline-block; padding: 12px 15px 10px; margin-top: 100px;
    background-color: $white; border-radius: 4px;
    color: $bordeaux; text-align: center; font-weight: 500;
    &:hover,
    &:focus{background: rgba(255,255,255,0.8);}
}