.footer {
    position: relative; z-index: 1;
    padding: 65px 0;
    background: $white;
}
.footer__container {display: flex; align-items: flex-start;}
.footer__logo {display: block; margin-right: 90px;}
.footer__content {display: flex; align-items: flex-start; justify-content: space-between; flex: 1;}

.footer__newsletter {
    width: 380px; max-width: 100%; padding: 0 15px;
    // iframe {height: 36px !important;}
}
.footer__newsletter-label {display: block; margin-bottom: 15px; }
.footer__newsletter-form {
    display: flex; align-items: center; justify-content: space-between;
    width: 100%; max-width: 430px; margin: 0 auto;
    border-bottom: 1px solid $greyLight;
}
div.mce_inline_error{margin-top: 5px; color: red;}
#mce-responses{max-width: 370px; width: 100%;}
#mce-responses .response{margin-top: 10px;}
.footer__newsletter-email {
    width: 100%; padding-right: 20px;
    appearance: none; border: none;
    font-family: 'Elgoc'; font-size: 26px; line-height: 26px;
}
.footer__newsletter-submit {
    appearance: none; background: none; border: none;
    text-transform: uppercase; font-weight: 500;
}

.footer__contact {display: flex; align-items: center;}
.footer__contact-link {
    display: inline-block; margin-right: 40px; padding-top: 3px;
    font-weight: 500;
}
.footer__contact-lang {
    padding-right: 35px;
    border: none;
    font-family: 'Calibre'; font-size: 18px;
}
.footer__contact-socials {
    display: flex; align-items: center;
    padding-left: 50px;
    &--facebook {fill: $social-facebook;}
    &--twitter {fill: $social-twitter;}
    &--linkedin {fill: $social-linkedin;}
    &--instagram {fill: $social-instagram;}

}
.footer__contact-socials-link {
    display: inline-block; margin: 0 15px;
    cursor: pointer;
    .icon {display: block; width: 24px; height: 24px;}
}

.footer__contact .switchLang{margin: 0;}

@media (max-width: 1380px) {
    .footer__contact{display: block; text-align: right;}
    .footer__contact-socials{padding-top: 20px;}
}
@media (max-width : $mq-tablet) {
    .footer__container{justify-content: center; flex-wrap: wrap;}
    .footer__logo{margin-right: 0;}
    .footer__content{
        justify-content: center; flex-wrap: wrap; flex-basis: 100%; width: 100%;
        margin-top: 60px; 
    }
    .footer__newsletter-label{font-size: 24px; text-align: center;}
    .footer__contact{
        display: flex; justify-content: center; align-items: center; flex-wrap: wrap; width: 100%;
        margin-top: 60px; 
    }
    .footer__contact .switchLang{margin-top: 0;}
    .footer__contact-socials{padding-top: 0;}
}

@media (max-width : $mq-tablet-sm) {
    .footer__contact{}
    .footer__contact-link{width: 100%; margin: 10px 0; text-align: center;}
    .footer__contact-lang{margin: 10px auto;}
    .footer__contact-socials{width: 100%;  margin: 60px 0 0; padding: 0; justify-content: center;}
}
@media (max-width : $mq-mobile-sm) {
    .footer__newsletter-email{font-size: 24px;}
}