
.textarea,
.input {
    appearance: none;
    border: none; border-bottom: 1px solid $greyLight;
    font-family: 'Elgoc'; font-size: 26px; line-height: 37px; color: $black;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $black; opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $black;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
        color: $black;
    }
}
