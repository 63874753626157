.chapterTitle {
    padding: 35px 0;
}
.chapterTitle__container {

}
.chapterTitle__heading {
    position: relative;
    display: flex; justify-content: center;
    text-align: center; color: $bordeaux;
}
.chapterTitle__heading-number {
    position: absolute; left: 50%; top: 0;
    font-size: 160px; font-family: 'Elgoc';
    transform: translateX(-50%); opacity: 0.1;
}
.chapterTitle__heading-label {
    position: relative; z-index: 1;
    padding: 56px 0 88px;
    font-size: 22px; font-weight: 600;
}
.chapterTitle__title {
    margin-bottom: 30px;
    text-align: center; color: $bordeaux; font-size: 66px; line-height: 66px; font-family: 'Elgoc';
    blockquote{font-size: 1em; line-height: 1em;}
    @include mobile {
        font-size: 39px; line-height: 40px;
    }
}
.chapterTitle__share {
    display: flex; justify-content: center; align-items: center;
}