/* QUOTE */
.quote {
    padding: 35px 0; overflow: hidden;
}
.quote__container {
    position: relative;
}
.quote__content {
    display: flex; justify-content: center; align-items: center; flex-direction: column;
}
.quote__image {
    width: 100%;   
    transform: translateX(65px);
    transform: translateX(8%); 
    opacity: 0.6;
}
.quote__body {
    position: relative; z-index: 1;
    padding-top: 120px;
    font-family: 'Elgoc'; font-size: 52px; text-align: center; color: $green;
    &::before {
        content: "“"; position: absolute; left: 50%; top: 0;
        font-family: 'Elgoc'; font-size: 380px; color: $green;
        opacity: 0.1; transform: translateX(-50%);
    }
    &::after {
        content: "”"; position: absolute; left: 50%; top: 100%;
        font-family: 'Elgoc'; font-size: 380px; color: $green;
        opacity: 0.1; transform: translate(-50%, 18px);
    }
}
.quote__author {
    display: flex; align-self: flex-end; justify-content: center;
    width: 50%; min-width: 465px; margin-top: 40px;
    opacity: 0.6;
    @include tablet {
        align-self: center;
    }
}
.quote__author-text {
    width: 220px; padding: 25px 25px 0 0;
    font-size: 18px; line-height: 24px; font-weight: 300;
    @include tablet {
        padding: 30px 0 0;
        text-align: center;
    }
}
.quote__author-illustration {
    @include tablet {
        display: none;
    }
}

@media (max-width : $mq-medium) {
    .quote__body{font-size: 46px;}
}
@media (max-width : $mq-tablet) {
    .quote__image-container{display: none;}
    .quote__body{padding-top: 89px; font-size: 40px;}
    .quote__author{margin-top: 70px;}
}
@media (max-width : $mq-tablet-sm) {
    .quote__image-container{margin-bottom: -140px;}
    .quote__body{font-size: 32px;}
    .quote__author{min-width: 0;}
}
@media (max-width : $mq-mobile) {
    .quote__author{justify-content: flex-start;}
    .quote__author-text{padding-right: 0;}
    .quote__author-illustration{display: none;}
}